var Es = globalThis && globalThis.__spreadArray || function(e, t, r) {
  if (r || arguments.length === 2)
    for (var n = 0, a = t.length, s; n < a; n++)
      (s || !(n in t)) && (s || (s = Array.prototype.slice.call(t, 0, n)), s[n] = t[n]);
  return e.concat(s || Array.prototype.slice.call(t));
};
function ws(e) {
  return e;
}
function W(e, t, r, n, a, s, o, c, l) {
  switch (arguments.length) {
    case 1:
      return e;
    case 2:
      return function() {
        return t(e.apply(this, arguments));
      };
    case 3:
      return function() {
        return r(t(e.apply(this, arguments)));
      };
    case 4:
      return function() {
        return n(r(t(e.apply(this, arguments))));
      };
    case 5:
      return function() {
        return a(n(r(t(e.apply(this, arguments)))));
      };
    case 6:
      return function() {
        return s(a(n(r(t(e.apply(this, arguments))))));
      };
    case 7:
      return function() {
        return o(s(a(n(r(t(e.apply(this, arguments)))))));
      };
    case 8:
      return function() {
        return c(o(s(a(n(r(t(e.apply(this, arguments))))))));
      };
    case 9:
      return function() {
        return l(c(o(s(a(n(r(t(e.apply(this, arguments)))))))));
      };
  }
}
function m(e, t, r, n, a, s, o, c, l) {
  switch (arguments.length) {
    case 1:
      return e;
    case 2:
      return t(e);
    case 3:
      return r(t(e));
    case 4:
      return n(r(t(e)));
    case 5:
      return a(n(r(t(e))));
    case 6:
      return s(a(n(r(t(e)))));
    case 7:
      return o(s(a(n(r(t(e))))));
    case 8:
      return c(o(s(a(n(r(t(e)))))));
    case 9:
      return l(c(o(s(a(n(r(t(e))))))));
    default: {
      for (var u = arguments[0], h = 1; h < arguments.length; h++)
        u = arguments[h](u);
      return u;
    }
  }
}
var xs = function(e, t) {
  var r = typeof e == "number" ? function(n) {
    return n.length >= e;
  } : e;
  return function() {
    var n = Array.from(arguments);
    return r(arguments) ? t.apply(this, n) : function(a) {
      return t.apply(void 0, Es([a], n, !1));
    };
  };
}, js = function(e) {
  return {
    equals: function(t, r) {
      return t === r || e(t, r);
    }
  };
}, Os = function(e) {
  return js(function(t, r) {
    for (var n in e)
      if (!e[n].equals(t[n], r[n]))
        return !1;
    return !0;
  });
};
globalThis && globalThis.__spreadArray;
var Dn = function(e) {
  return e._tag === "Some";
}, Zn = { _tag: "None" }, Un = function(e) {
  return { _tag: "Some", value: e };
}, ks = function(e) {
  return e._tag === "Left";
}, Rs = function(e) {
  return e._tag === "Right";
}, Cs = function(e) {
  return { _tag: "Left", left: e };
}, Is = function(e) {
  return { _tag: "Right", right: e };
}, Ns = function(e) {
  return [e];
}, Ps = [], Vs = Object.prototype.hasOwnProperty, Ms = function(e) {
  return function(t) {
    return function(r) {
      return r.reduce(function(n, a) {
        return e.concat(n, a);
      }, t);
    };
  };
}, Ls = function(e) {
  return {
    concat: function() {
      return e;
    }
  };
}, Ds = function(e) {
  return {
    concat: function(t, r) {
      var n = {};
      for (var a in e)
        Vs.call(e, a) && (n[a] = e[a].concat(t[a], r[a]));
      return n;
    }
  };
}, Gt = function() {
  return { concat: ws };
}, Zs = function() {
  return { concat: function(e, t) {
    return t;
  } };
}, Us = Ms, Hs = Ls(void 0), Gr = globalThis && globalThis.__spreadArray || function(e, t, r) {
  if (r || arguments.length === 2)
    for (var n = 0, a = t.length, s; n < a; n++)
      (s || !(n in t)) && (s || (s = Array.prototype.slice.call(t, 0, n)), s[n] = t[n]);
  return e.concat(s || Array.prototype.slice.call(t));
}, qs = Ps, Bs = function(e, t) {
  return e < 0 || e >= t.length;
}, Gs = function(e) {
  return function(t) {
    return Gr(Gr([], t, !0), [e], !1);
  };
}, zs = Gs, Ks = Ns, F = {
  equals: function(e, t) {
    return e === t;
  }
}, Fs = {
  concat: function(e, t) {
    return e + t;
  }
}, Qs = "", lr = {
  concat: Fs.concat,
  empty: Qs
};
F.equals;
var Hn = function(e) {
  return e.trim();
}, qn = function(e) {
  return e.length === 0;
}, it = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function Bn(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
function Ws() {
  this.__data__ = [], this.size = 0;
}
var Ys = Ws;
function Js(e, t) {
  return e === t || e !== e && t !== t;
}
var dr = Js, Xs = dr;
function ei(e, t) {
  for (var r = e.length; r--; )
    if (Xs(e[r][0], t))
      return r;
  return -1;
}
var xt = ei, ti = xt, ri = Array.prototype, ni = ri.splice;
function ai(e) {
  var t = this.__data__, r = ti(t, e);
  if (r < 0)
    return !1;
  var n = t.length - 1;
  return r == n ? t.pop() : ni.call(t, r, 1), --this.size, !0;
}
var si = ai, ii = xt;
function oi(e) {
  var t = this.__data__, r = ii(t, e);
  return r < 0 ? void 0 : t[r][1];
}
var ci = oi, ui = xt;
function li(e) {
  return ui(this.__data__, e) > -1;
}
var di = li, fi = xt;
function pi(e, t) {
  var r = this.__data__, n = fi(r, e);
  return n < 0 ? (++this.size, r.push([e, t])) : r[n][1] = t, this;
}
var hi = pi, vi = Ys, yi = si, mi = ci, gi = di, _i = hi;
function be(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
be.prototype.clear = vi;
be.prototype.delete = yi;
be.prototype.get = mi;
be.prototype.has = gi;
be.prototype.set = _i;
var jt = be, bi = jt;
function Ti() {
  this.__data__ = new bi(), this.size = 0;
}
var $i = Ti;
function Ai(e) {
  var t = this.__data__, r = t.delete(e);
  return this.size = t.size, r;
}
var Si = Ai;
function Ei(e) {
  return this.__data__.get(e);
}
var wi = Ei;
function xi(e) {
  return this.__data__.has(e);
}
var ji = xi, Oi = typeof it == "object" && it && it.Object === Object && it, Gn = Oi, ki = Gn, Ri = typeof self == "object" && self && self.Object === Object && self, Ci = ki || Ri || Function("return this")(), G = Ci, Ii = G, Ni = Ii.Symbol, Ot = Ni, zr = Ot, zn = Object.prototype, Pi = zn.hasOwnProperty, Vi = zn.toString, we = zr ? zr.toStringTag : void 0;
function Mi(e) {
  var t = Pi.call(e, we), r = e[we];
  try {
    e[we] = void 0;
    var n = !0;
  } catch {
  }
  var a = Vi.call(e);
  return n && (t ? e[we] = r : delete e[we]), a;
}
var Li = Mi, Di = Object.prototype, Zi = Di.toString;
function Ui(e) {
  return Zi.call(e);
}
var Hi = Ui, Kr = Ot, qi = Li, Bi = Hi, Gi = "[object Null]", zi = "[object Undefined]", Fr = Kr ? Kr.toStringTag : void 0;
function Ki(e) {
  return e == null ? e === void 0 ? zi : Gi : Fr && Fr in Object(e) ? qi(e) : Bi(e);
}
var kt = Ki;
function Fi(e) {
  var t = typeof e;
  return e != null && (t == "object" || t == "function");
}
var Ue = Fi, Qi = kt, Wi = Ue, Yi = "[object AsyncFunction]", Ji = "[object Function]", Xi = "[object GeneratorFunction]", eo = "[object Proxy]";
function to(e) {
  if (!Wi(e))
    return !1;
  var t = Qi(e);
  return t == Ji || t == Xi || t == Yi || t == eo;
}
var Kn = to, ro = G, no = ro["__core-js_shared__"], ao = no, zt = ao, Qr = function() {
  var e = /[^.]+$/.exec(zt && zt.keys && zt.keys.IE_PROTO || "");
  return e ? "Symbol(src)_1." + e : "";
}();
function so(e) {
  return !!Qr && Qr in e;
}
var io = so, oo = Function.prototype, co = oo.toString;
function uo(e) {
  if (e != null) {
    try {
      return co.call(e);
    } catch {
    }
    try {
      return e + "";
    } catch {
    }
  }
  return "";
}
var Fn = uo, lo = Kn, fo = io, po = Ue, ho = Fn, vo = /[\\^$.*+?()[\]{}|]/g, yo = /^\[object .+?Constructor\]$/, mo = Function.prototype, go = Object.prototype, _o = mo.toString, bo = go.hasOwnProperty, To = RegExp(
  "^" + _o.call(bo).replace(vo, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$"
);
function $o(e) {
  if (!po(e) || fo(e))
    return !1;
  var t = lo(e) ? To : yo;
  return t.test(ho(e));
}
var Ao = $o;
function So(e, t) {
  return e == null ? void 0 : e[t];
}
var Eo = So, wo = Ao, xo = Eo;
function jo(e, t) {
  var r = xo(e, t);
  return wo(r) ? r : void 0;
}
var ue = jo, Oo = ue, ko = G, Ro = Oo(ko, "Map"), fr = Ro, Co = ue, Io = Co(Object, "create"), Rt = Io, Wr = Rt;
function No() {
  this.__data__ = Wr ? Wr(null) : {}, this.size = 0;
}
var Po = No;
function Vo(e) {
  var t = this.has(e) && delete this.__data__[e];
  return this.size -= t ? 1 : 0, t;
}
var Mo = Vo, Lo = Rt, Do = "__lodash_hash_undefined__", Zo = Object.prototype, Uo = Zo.hasOwnProperty;
function Ho(e) {
  var t = this.__data__;
  if (Lo) {
    var r = t[e];
    return r === Do ? void 0 : r;
  }
  return Uo.call(t, e) ? t[e] : void 0;
}
var qo = Ho, Bo = Rt, Go = Object.prototype, zo = Go.hasOwnProperty;
function Ko(e) {
  var t = this.__data__;
  return Bo ? t[e] !== void 0 : zo.call(t, e);
}
var Fo = Ko, Qo = Rt, Wo = "__lodash_hash_undefined__";
function Yo(e, t) {
  var r = this.__data__;
  return this.size += this.has(e) ? 0 : 1, r[e] = Qo && t === void 0 ? Wo : t, this;
}
var Jo = Yo, Xo = Po, ec = Mo, tc = qo, rc = Fo, nc = Jo;
function Te(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
Te.prototype.clear = Xo;
Te.prototype.delete = ec;
Te.prototype.get = tc;
Te.prototype.has = rc;
Te.prototype.set = nc;
var ac = Te, Yr = ac, sc = jt, ic = fr;
function oc() {
  this.size = 0, this.__data__ = {
    hash: new Yr(),
    map: new (ic || sc)(),
    string: new Yr()
  };
}
var cc = oc;
function uc(e) {
  var t = typeof e;
  return t == "string" || t == "number" || t == "symbol" || t == "boolean" ? e !== "__proto__" : e === null;
}
var lc = uc, dc = lc;
function fc(e, t) {
  var r = e.__data__;
  return dc(t) ? r[typeof t == "string" ? "string" : "hash"] : r.map;
}
var Ct = fc, pc = Ct;
function hc(e) {
  var t = pc(this, e).delete(e);
  return this.size -= t ? 1 : 0, t;
}
var vc = hc, yc = Ct;
function mc(e) {
  return yc(this, e).get(e);
}
var gc = mc, _c = Ct;
function bc(e) {
  return _c(this, e).has(e);
}
var Tc = bc, $c = Ct;
function Ac(e, t) {
  var r = $c(this, e), n = r.size;
  return r.set(e, t), this.size += r.size == n ? 0 : 1, this;
}
var Sc = Ac, Ec = cc, wc = vc, xc = gc, jc = Tc, Oc = Sc;
function $e(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
$e.prototype.clear = Ec;
$e.prototype.delete = wc;
$e.prototype.get = xc;
$e.prototype.has = jc;
$e.prototype.set = Oc;
var Qn = $e, kc = jt, Rc = fr, Cc = Qn, Ic = 200;
function Nc(e, t) {
  var r = this.__data__;
  if (r instanceof kc) {
    var n = r.__data__;
    if (!Rc || n.length < Ic - 1)
      return n.push([e, t]), this.size = ++r.size, this;
    r = this.__data__ = new Cc(n);
  }
  return r.set(e, t), this.size = r.size, this;
}
var Pc = Nc, Vc = jt, Mc = $i, Lc = Si, Dc = wi, Zc = ji, Uc = Pc;
function Ae(e) {
  var t = this.__data__ = new Vc(e);
  this.size = t.size;
}
Ae.prototype.clear = Mc;
Ae.prototype.delete = Lc;
Ae.prototype.get = Dc;
Ae.prototype.has = Zc;
Ae.prototype.set = Uc;
var Wn = Ae;
function Hc(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length; ++r < n && t(e[r], r, e) !== !1; )
    ;
  return e;
}
var qc = Hc, Bc = ue, Gc = function() {
  try {
    var e = Bc(Object, "defineProperty");
    return e({}, "", {}), e;
  } catch {
  }
}(), zc = Gc, Jr = zc;
function Kc(e, t, r) {
  t == "__proto__" && Jr ? Jr(e, t, {
    configurable: !0,
    enumerable: !0,
    value: r,
    writable: !0
  }) : e[t] = r;
}
var Yn = Kc, Fc = Yn, Qc = dr, Wc = Object.prototype, Yc = Wc.hasOwnProperty;
function Jc(e, t, r) {
  var n = e[t];
  (!(Yc.call(e, t) && Qc(n, r)) || r === void 0 && !(t in e)) && Fc(e, t, r);
}
var Jn = Jc, Xc = Jn, eu = Yn;
function tu(e, t, r, n) {
  var a = !r;
  r || (r = {});
  for (var s = -1, o = t.length; ++s < o; ) {
    var c = t[s], l = n ? n(r[c], e[c], c, r, e) : void 0;
    l === void 0 && (l = e[c]), a ? eu(r, c, l) : Xc(r, c, l);
  }
  return r;
}
var It = tu;
function ru(e, t) {
  for (var r = -1, n = Array(e); ++r < e; )
    n[r] = t(r);
  return n;
}
var nu = ru;
function au(e) {
  return e != null && typeof e == "object";
}
var Se = au, su = kt, iu = Se, ou = "[object Arguments]";
function cu(e) {
  return iu(e) && su(e) == ou;
}
var uu = cu, Xr = uu, lu = Se, Xn = Object.prototype, du = Xn.hasOwnProperty, fu = Xn.propertyIsEnumerable, pu = Xr(function() {
  return arguments;
}()) ? Xr : function(e) {
  return lu(e) && du.call(e, "callee") && !fu.call(e, "callee");
}, hu = pu, vu = Array.isArray, Nt = vu, lt = { exports: {} };
function yu() {
  return !1;
}
var mu = yu;
lt.exports;
(function(e, t) {
  var r = G, n = mu, a = t && !t.nodeType && t, s = a && !0 && e && !e.nodeType && e, o = s && s.exports === a, c = o ? r.Buffer : void 0, l = c ? c.isBuffer : void 0, u = l || n;
  e.exports = u;
})(lt, lt.exports);
var pr = lt.exports, gu = 9007199254740991, _u = /^(?:0|[1-9]\d*)$/;
function bu(e, t) {
  var r = typeof e;
  return t = t ?? gu, !!t && (r == "number" || r != "symbol" && _u.test(e)) && e > -1 && e % 1 == 0 && e < t;
}
var Tu = bu, $u = 9007199254740991;
function Au(e) {
  return typeof e == "number" && e > -1 && e % 1 == 0 && e <= $u;
}
var ea = Au, Su = kt, Eu = ea, wu = Se, xu = "[object Arguments]", ju = "[object Array]", Ou = "[object Boolean]", ku = "[object Date]", Ru = "[object Error]", Cu = "[object Function]", Iu = "[object Map]", Nu = "[object Number]", Pu = "[object Object]", Vu = "[object RegExp]", Mu = "[object Set]", Lu = "[object String]", Du = "[object WeakMap]", Zu = "[object ArrayBuffer]", Uu = "[object DataView]", Hu = "[object Float32Array]", qu = "[object Float64Array]", Bu = "[object Int8Array]", Gu = "[object Int16Array]", zu = "[object Int32Array]", Ku = "[object Uint8Array]", Fu = "[object Uint8ClampedArray]", Qu = "[object Uint16Array]", Wu = "[object Uint32Array]", E = {};
E[Hu] = E[qu] = E[Bu] = E[Gu] = E[zu] = E[Ku] = E[Fu] = E[Qu] = E[Wu] = !0;
E[xu] = E[ju] = E[Zu] = E[Ou] = E[Uu] = E[ku] = E[Ru] = E[Cu] = E[Iu] = E[Nu] = E[Pu] = E[Vu] = E[Mu] = E[Lu] = E[Du] = !1;
function Yu(e) {
  return wu(e) && Eu(e.length) && !!E[Su(e)];
}
var Ju = Yu;
function Xu(e) {
  return function(t) {
    return e(t);
  };
}
var hr = Xu, dt = { exports: {} };
dt.exports;
(function(e, t) {
  var r = Gn, n = t && !t.nodeType && t, a = n && !0 && e && !e.nodeType && e, s = a && a.exports === n, o = s && r.process, c = function() {
    try {
      var l = a && a.require && a.require("util").types;
      return l || o && o.binding && o.binding("util");
    } catch {
    }
  }();
  e.exports = c;
})(dt, dt.exports);
var vr = dt.exports, el = Ju, tl = hr, en = vr, tn = en && en.isTypedArray, rl = tn ? tl(tn) : el, ta = rl, nl = nu, al = hu, sl = Nt, il = pr, ol = Tu, cl = ta, ul = Object.prototype, ll = ul.hasOwnProperty;
function dl(e, t) {
  var r = sl(e), n = !r && al(e), a = !r && !n && il(e), s = !r && !n && !a && cl(e), o = r || n || a || s, c = o ? nl(e.length, String) : [], l = c.length;
  for (var u in e)
    (t || ll.call(e, u)) && !(o && // Safari 9 has enumerable `arguments.length` in strict mode.
    (u == "length" || // Node.js 0.10 has enumerable non-index properties on buffers.
    a && (u == "offset" || u == "parent") || // PhantomJS 2 has enumerable non-index properties on typed arrays.
    s && (u == "buffer" || u == "byteLength" || u == "byteOffset") || // Skip index properties.
    ol(u, l))) && c.push(u);
  return c;
}
var ra = dl, fl = Object.prototype;
function pl(e) {
  var t = e && e.constructor, r = typeof t == "function" && t.prototype || fl;
  return e === r;
}
var yr = pl;
function hl(e, t) {
  return function(r) {
    return e(t(r));
  };
}
var na = hl, vl = na, yl = vl(Object.keys, Object), ml = yl, gl = yr, _l = ml, bl = Object.prototype, Tl = bl.hasOwnProperty;
function $l(e) {
  if (!gl(e))
    return _l(e);
  var t = [];
  for (var r in Object(e))
    Tl.call(e, r) && r != "constructor" && t.push(r);
  return t;
}
var Al = $l, Sl = Kn, El = ea;
function wl(e) {
  return e != null && El(e.length) && !Sl(e);
}
var aa = wl, xl = ra, jl = Al, Ol = aa;
function kl(e) {
  return Ol(e) ? xl(e) : jl(e);
}
var mr = kl, Rl = It, Cl = mr;
function Il(e, t) {
  return e && Rl(t, Cl(t), e);
}
var Nl = Il;
function Pl(e) {
  var t = [];
  if (e != null)
    for (var r in Object(e))
      t.push(r);
  return t;
}
var Vl = Pl, Ml = Ue, Ll = yr, Dl = Vl, Zl = Object.prototype, Ul = Zl.hasOwnProperty;
function Hl(e) {
  if (!Ml(e))
    return Dl(e);
  var t = Ll(e), r = [];
  for (var n in e)
    n == "constructor" && (t || !Ul.call(e, n)) || r.push(n);
  return r;
}
var ql = Hl, Bl = ra, Gl = ql, zl = aa;
function Kl(e) {
  return zl(e) ? Bl(e, !0) : Gl(e);
}
var gr = Kl, Fl = It, Ql = gr;
function Wl(e, t) {
  return e && Fl(t, Ql(t), e);
}
var Yl = Wl, ft = { exports: {} };
ft.exports;
(function(e, t) {
  var r = G, n = t && !t.nodeType && t, a = n && !0 && e && !e.nodeType && e, s = a && a.exports === n, o = s ? r.Buffer : void 0, c = o ? o.allocUnsafe : void 0;
  function l(u, h) {
    if (h)
      return u.slice();
    var T = u.length, A = c ? c(T) : new u.constructor(T);
    return u.copy(A), A;
  }
  e.exports = l;
})(ft, ft.exports);
var Jl = ft.exports;
function Xl(e, t) {
  var r = -1, n = e.length;
  for (t || (t = Array(n)); ++r < n; )
    t[r] = e[r];
  return t;
}
var ed = Xl;
function td(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length, a = 0, s = []; ++r < n; ) {
    var o = e[r];
    t(o, r, e) && (s[a++] = o);
  }
  return s;
}
var rd = td;
function nd() {
  return [];
}
var sa = nd, ad = rd, sd = sa, id = Object.prototype, od = id.propertyIsEnumerable, rn = Object.getOwnPropertySymbols, cd = rn ? function(e) {
  return e == null ? [] : (e = Object(e), ad(rn(e), function(t) {
    return od.call(e, t);
  }));
} : sd, _r = cd, ud = It, ld = _r;
function dd(e, t) {
  return ud(e, ld(e), t);
}
var fd = dd;
function pd(e, t) {
  for (var r = -1, n = t.length, a = e.length; ++r < n; )
    e[a + r] = t[r];
  return e;
}
var ia = pd, hd = na, vd = hd(Object.getPrototypeOf, Object), oa = vd, yd = ia, md = oa, gd = _r, _d = sa, bd = Object.getOwnPropertySymbols, Td = bd ? function(e) {
  for (var t = []; e; )
    yd(t, gd(e)), e = md(e);
  return t;
} : _d, ca = Td, $d = It, Ad = ca;
function Sd(e, t) {
  return $d(e, Ad(e), t);
}
var Ed = Sd, wd = ia, xd = Nt;
function jd(e, t, r) {
  var n = t(e);
  return xd(e) ? n : wd(n, r(e));
}
var ua = jd, Od = ua, kd = _r, Rd = mr;
function Cd(e) {
  return Od(e, Rd, kd);
}
var la = Cd, Id = ua, Nd = ca, Pd = gr;
function Vd(e) {
  return Id(e, Pd, Nd);
}
var Md = Vd, Ld = ue, Dd = G, Zd = Ld(Dd, "DataView"), Ud = Zd, Hd = ue, qd = G, Bd = Hd(qd, "Promise"), Gd = Bd, zd = ue, Kd = G, Fd = zd(Kd, "Set"), Qd = Fd, Wd = ue, Yd = G, Jd = Wd(Yd, "WeakMap"), Xd = Jd, Yt = Ud, Jt = fr, Xt = Gd, er = Qd, tr = Xd, da = kt, Ee = Fn, nn = "[object Map]", ef = "[object Object]", an = "[object Promise]", sn = "[object Set]", on = "[object WeakMap]", cn = "[object DataView]", tf = Ee(Yt), rf = Ee(Jt), nf = Ee(Xt), af = Ee(er), sf = Ee(tr), ae = da;
(Yt && ae(new Yt(new ArrayBuffer(1))) != cn || Jt && ae(new Jt()) != nn || Xt && ae(Xt.resolve()) != an || er && ae(new er()) != sn || tr && ae(new tr()) != on) && (ae = function(e) {
  var t = da(e), r = t == ef ? e.constructor : void 0, n = r ? Ee(r) : "";
  if (n)
    switch (n) {
      case tf:
        return cn;
      case rf:
        return nn;
      case nf:
        return an;
      case af:
        return sn;
      case sf:
        return on;
    }
  return t;
});
var Pt = ae, of = Object.prototype, cf = of.hasOwnProperty;
function uf(e) {
  var t = e.length, r = new e.constructor(t);
  return t && typeof e[0] == "string" && cf.call(e, "index") && (r.index = e.index, r.input = e.input), r;
}
var lf = uf, df = G, ff = df.Uint8Array, fa = ff, un = fa;
function pf(e) {
  var t = new e.constructor(e.byteLength);
  return new un(t).set(new un(e)), t;
}
var br = pf, hf = br;
function vf(e, t) {
  var r = t ? hf(e.buffer) : e.buffer;
  return new e.constructor(r, e.byteOffset, e.byteLength);
}
var yf = vf, mf = /\w*$/;
function gf(e) {
  var t = new e.constructor(e.source, mf.exec(e));
  return t.lastIndex = e.lastIndex, t;
}
var _f = gf, ln = Ot, dn = ln ? ln.prototype : void 0, fn = dn ? dn.valueOf : void 0;
function bf(e) {
  return fn ? Object(fn.call(e)) : {};
}
var Tf = bf, $f = br;
function Af(e, t) {
  var r = t ? $f(e.buffer) : e.buffer;
  return new e.constructor(r, e.byteOffset, e.length);
}
var Sf = Af, Ef = br, wf = yf, xf = _f, jf = Tf, Of = Sf, kf = "[object Boolean]", Rf = "[object Date]", Cf = "[object Map]", If = "[object Number]", Nf = "[object RegExp]", Pf = "[object Set]", Vf = "[object String]", Mf = "[object Symbol]", Lf = "[object ArrayBuffer]", Df = "[object DataView]", Zf = "[object Float32Array]", Uf = "[object Float64Array]", Hf = "[object Int8Array]", qf = "[object Int16Array]", Bf = "[object Int32Array]", Gf = "[object Uint8Array]", zf = "[object Uint8ClampedArray]", Kf = "[object Uint16Array]", Ff = "[object Uint32Array]";
function Qf(e, t, r) {
  var n = e.constructor;
  switch (t) {
    case Lf:
      return Ef(e);
    case kf:
    case Rf:
      return new n(+e);
    case Df:
      return wf(e, r);
    case Zf:
    case Uf:
    case Hf:
    case qf:
    case Bf:
    case Gf:
    case zf:
    case Kf:
    case Ff:
      return Of(e, r);
    case Cf:
      return new n();
    case If:
    case Vf:
      return new n(e);
    case Nf:
      return xf(e);
    case Pf:
      return new n();
    case Mf:
      return jf(e);
  }
}
var Wf = Qf, Yf = Ue, pn = Object.create, Jf = function() {
  function e() {
  }
  return function(t) {
    if (!Yf(t))
      return {};
    if (pn)
      return pn(t);
    e.prototype = t;
    var r = new e();
    return e.prototype = void 0, r;
  };
}(), Xf = Jf, ep = Xf, tp = oa, rp = yr;
function np(e) {
  return typeof e.constructor == "function" && !rp(e) ? ep(tp(e)) : {};
}
var ap = np, sp = Pt, ip = Se, op = "[object Map]";
function cp(e) {
  return ip(e) && sp(e) == op;
}
var up = cp, lp = up, dp = hr, hn = vr, vn = hn && hn.isMap, fp = vn ? dp(vn) : lp, pp = fp, hp = Pt, vp = Se, yp = "[object Set]";
function mp(e) {
  return vp(e) && hp(e) == yp;
}
var gp = mp, _p = gp, bp = hr, yn = vr, mn = yn && yn.isSet, Tp = mn ? bp(mn) : _p, $p = Tp, Ap = Wn, Sp = qc, Ep = Jn, wp = Nl, xp = Yl, jp = Jl, Op = ed, kp = fd, Rp = Ed, Cp = la, Ip = Md, Np = Pt, Pp = lf, Vp = Wf, Mp = ap, Lp = Nt, Dp = pr, Zp = pp, Up = Ue, Hp = $p, qp = mr, Bp = gr, Gp = 1, zp = 2, Kp = 4, pa = "[object Arguments]", Fp = "[object Array]", Qp = "[object Boolean]", Wp = "[object Date]", Yp = "[object Error]", ha = "[object Function]", Jp = "[object GeneratorFunction]", Xp = "[object Map]", eh = "[object Number]", va = "[object Object]", th = "[object RegExp]", rh = "[object Set]", nh = "[object String]", ah = "[object Symbol]", sh = "[object WeakMap]", ih = "[object ArrayBuffer]", oh = "[object DataView]", ch = "[object Float32Array]", uh = "[object Float64Array]", lh = "[object Int8Array]", dh = "[object Int16Array]", fh = "[object Int32Array]", ph = "[object Uint8Array]", hh = "[object Uint8ClampedArray]", vh = "[object Uint16Array]", yh = "[object Uint32Array]", S = {};
S[pa] = S[Fp] = S[ih] = S[oh] = S[Qp] = S[Wp] = S[ch] = S[uh] = S[lh] = S[dh] = S[fh] = S[Xp] = S[eh] = S[va] = S[th] = S[rh] = S[nh] = S[ah] = S[ph] = S[hh] = S[vh] = S[yh] = !0;
S[Yp] = S[ha] = S[sh] = !1;
function ct(e, t, r, n, a, s) {
  var o, c = t & Gp, l = t & zp, u = t & Kp;
  if (r && (o = a ? r(e, n, a, s) : r(e)), o !== void 0)
    return o;
  if (!Up(e))
    return e;
  var h = Lp(e);
  if (h) {
    if (o = Pp(e), !c)
      return Op(e, o);
  } else {
    var T = Np(e), A = T == ha || T == Jp;
    if (Dp(e))
      return jp(e, c);
    if (T == va || T == pa || A && !a) {
      if (o = l || A ? {} : Mp(e), !c)
        return l ? Rp(e, xp(o, e)) : kp(e, wp(o, e));
    } else {
      if (!S[T])
        return a ? e : {};
      o = Vp(e, T, c);
    }
  }
  s || (s = new Ap());
  var C = s.get(e);
  if (C)
    return C;
  s.set(e, o), Hp(e) ? e.forEach(function(R) {
    o.add(ct(R, t, r, R, e, s));
  }) : Zp(e) && e.forEach(function(R, N) {
    o.set(N, ct(R, t, r, N, e, s));
  });
  var V = u ? l ? Ip : Cp : l ? Bp : qp, M = h ? void 0 : V(e);
  return Sp(M || e, function(R, N) {
    M && (N = R, R = e[N]), Ep(o, N, ct(R, t, r, N, e, s));
  }), o;
}
var mh = ct, gh = mh, _h = 1, bh = 4;
function Th(e) {
  return gh(e, _h | bh);
}
var $h = Th;
const Ah = /* @__PURE__ */ Bn($h);
var $;
(function(e) {
  e.assertEqual = (a) => a;
  function t(a) {
  }
  e.assertIs = t;
  function r(a) {
    throw new Error();
  }
  e.assertNever = r, e.arrayToEnum = (a) => {
    const s = {};
    for (const o of a)
      s[o] = o;
    return s;
  }, e.getValidEnumValues = (a) => {
    const s = e.objectKeys(a).filter((c) => typeof a[a[c]] != "number"), o = {};
    for (const c of s)
      o[c] = a[c];
    return e.objectValues(o);
  }, e.objectValues = (a) => e.objectKeys(a).map(function(s) {
    return a[s];
  }), e.objectKeys = typeof Object.keys == "function" ? (a) => Object.keys(a) : (a) => {
    const s = [];
    for (const o in a)
      Object.prototype.hasOwnProperty.call(a, o) && s.push(o);
    return s;
  }, e.find = (a, s) => {
    for (const o of a)
      if (s(o))
        return o;
  }, e.isInteger = typeof Number.isInteger == "function" ? (a) => Number.isInteger(a) : (a) => typeof a == "number" && isFinite(a) && Math.floor(a) === a;
  function n(a, s = " | ") {
    return a.map((o) => typeof o == "string" ? `'${o}'` : o).join(s);
  }
  e.joinValues = n, e.jsonStringifyReplacer = (a, s) => typeof s == "bigint" ? s.toString() : s;
})($ || ($ = {}));
var rr;
(function(e) {
  e.mergeShapes = (t, r) => ({
    ...t,
    ...r
    // second overwrites first
  });
})(rr || (rr = {}));
const f = $.arrayToEnum([
  "string",
  "nan",
  "number",
  "integer",
  "float",
  "boolean",
  "date",
  "bigint",
  "symbol",
  "function",
  "undefined",
  "null",
  "array",
  "object",
  "unknown",
  "promise",
  "void",
  "never",
  "map",
  "set"
]), Q = (e) => {
  switch (typeof e) {
    case "undefined":
      return f.undefined;
    case "string":
      return f.string;
    case "number":
      return isNaN(e) ? f.nan : f.number;
    case "boolean":
      return f.boolean;
    case "function":
      return f.function;
    case "bigint":
      return f.bigint;
    case "symbol":
      return f.symbol;
    case "object":
      return Array.isArray(e) ? f.array : e === null ? f.null : e.then && typeof e.then == "function" && e.catch && typeof e.catch == "function" ? f.promise : typeof Map < "u" && e instanceof Map ? f.map : typeof Set < "u" && e instanceof Set ? f.set : typeof Date < "u" && e instanceof Date ? f.date : f.object;
    default:
      return f.unknown;
  }
}, d = $.arrayToEnum([
  "invalid_type",
  "invalid_literal",
  "custom",
  "invalid_union",
  "invalid_union_discriminator",
  "invalid_enum_value",
  "unrecognized_keys",
  "invalid_arguments",
  "invalid_return_type",
  "invalid_date",
  "invalid_string",
  "too_small",
  "too_big",
  "invalid_intersection_types",
  "not_multiple_of",
  "not_finite"
]), Sh = (e) => JSON.stringify(e, null, 2).replace(/"([^"]+)":/g, "$1:");
class D extends Error {
  constructor(t) {
    super(), this.issues = [], this.addIssue = (n) => {
      this.issues = [...this.issues, n];
    }, this.addIssues = (n = []) => {
      this.issues = [...this.issues, ...n];
    };
    const r = new.target.prototype;
    Object.setPrototypeOf ? Object.setPrototypeOf(this, r) : this.__proto__ = r, this.name = "ZodError", this.issues = t;
  }
  get errors() {
    return this.issues;
  }
  format(t) {
    const r = t || function(s) {
      return s.message;
    }, n = { _errors: [] }, a = (s) => {
      for (const o of s.issues)
        if (o.code === "invalid_union")
          o.unionErrors.map(a);
        else if (o.code === "invalid_return_type")
          a(o.returnTypeError);
        else if (o.code === "invalid_arguments")
          a(o.argumentsError);
        else if (o.path.length === 0)
          n._errors.push(r(o));
        else {
          let c = n, l = 0;
          for (; l < o.path.length; ) {
            const u = o.path[l];
            l === o.path.length - 1 ? (c[u] = c[u] || { _errors: [] }, c[u]._errors.push(r(o))) : c[u] = c[u] || { _errors: [] }, c = c[u], l++;
          }
        }
    };
    return a(this), n;
  }
  toString() {
    return this.message;
  }
  get message() {
    return JSON.stringify(this.issues, $.jsonStringifyReplacer, 2);
  }
  get isEmpty() {
    return this.issues.length === 0;
  }
  flatten(t = (r) => r.message) {
    const r = {}, n = [];
    for (const a of this.issues)
      a.path.length > 0 ? (r[a.path[0]] = r[a.path[0]] || [], r[a.path[0]].push(t(a))) : n.push(t(a));
    return { formErrors: n, fieldErrors: r };
  }
  get formErrors() {
    return this.flatten();
  }
}
D.create = (e) => new D(e);
const je = (e, t) => {
  let r;
  switch (e.code) {
    case d.invalid_type:
      e.received === f.undefined ? r = "Required" : r = `Expected ${e.expected}, received ${e.received}`;
      break;
    case d.invalid_literal:
      r = `Invalid literal value, expected ${JSON.stringify(e.expected, $.jsonStringifyReplacer)}`;
      break;
    case d.unrecognized_keys:
      r = `Unrecognized key(s) in object: ${$.joinValues(e.keys, ", ")}`;
      break;
    case d.invalid_union:
      r = "Invalid input";
      break;
    case d.invalid_union_discriminator:
      r = `Invalid discriminator value. Expected ${$.joinValues(e.options)}`;
      break;
    case d.invalid_enum_value:
      r = `Invalid enum value. Expected ${$.joinValues(e.options)}, received '${e.received}'`;
      break;
    case d.invalid_arguments:
      r = "Invalid function arguments";
      break;
    case d.invalid_return_type:
      r = "Invalid function return type";
      break;
    case d.invalid_date:
      r = "Invalid date";
      break;
    case d.invalid_string:
      typeof e.validation == "object" ? "includes" in e.validation ? (r = `Invalid input: must include "${e.validation.includes}"`, typeof e.validation.position == "number" && (r = `${r} at one or more positions greater than or equal to ${e.validation.position}`)) : "startsWith" in e.validation ? r = `Invalid input: must start with "${e.validation.startsWith}"` : "endsWith" in e.validation ? r = `Invalid input: must end with "${e.validation.endsWith}"` : $.assertNever(e.validation) : e.validation !== "regex" ? r = `Invalid ${e.validation}` : r = "Invalid";
      break;
    case d.too_small:
      e.type === "array" ? r = `Array must contain ${e.exact ? "exactly" : e.inclusive ? "at least" : "more than"} ${e.minimum} element(s)` : e.type === "string" ? r = `String must contain ${e.exact ? "exactly" : e.inclusive ? "at least" : "over"} ${e.minimum} character(s)` : e.type === "number" ? r = `Number must be ${e.exact ? "exactly equal to " : e.inclusive ? "greater than or equal to " : "greater than "}${e.minimum}` : e.type === "date" ? r = `Date must be ${e.exact ? "exactly equal to " : e.inclusive ? "greater than or equal to " : "greater than "}${new Date(Number(e.minimum))}` : r = "Invalid input";
      break;
    case d.too_big:
      e.type === "array" ? r = `Array must contain ${e.exact ? "exactly" : e.inclusive ? "at most" : "less than"} ${e.maximum} element(s)` : e.type === "string" ? r = `String must contain ${e.exact ? "exactly" : e.inclusive ? "at most" : "under"} ${e.maximum} character(s)` : e.type === "number" ? r = `Number must be ${e.exact ? "exactly" : e.inclusive ? "less than or equal to" : "less than"} ${e.maximum}` : e.type === "bigint" ? r = `BigInt must be ${e.exact ? "exactly" : e.inclusive ? "less than or equal to" : "less than"} ${e.maximum}` : e.type === "date" ? r = `Date must be ${e.exact ? "exactly" : e.inclusive ? "smaller than or equal to" : "smaller than"} ${new Date(Number(e.maximum))}` : r = "Invalid input";
      break;
    case d.custom:
      r = "Invalid input";
      break;
    case d.invalid_intersection_types:
      r = "Intersection results could not be merged";
      break;
    case d.not_multiple_of:
      r = `Number must be a multiple of ${e.multipleOf}`;
      break;
    case d.not_finite:
      r = "Number must be finite";
      break;
    default:
      r = t.defaultError, $.assertNever(e);
  }
  return { message: r };
};
let ya = je;
function Eh(e) {
  ya = e;
}
function pt() {
  return ya;
}
const ht = (e) => {
  const { data: t, path: r, errorMaps: n, issueData: a } = e, s = [...r, ...a.path || []], o = {
    ...a,
    path: s
  };
  let c = "";
  const l = n.filter((u) => !!u).slice().reverse();
  for (const u of l)
    c = u(o, { data: t, defaultError: c }).message;
  return {
    ...a,
    path: s,
    message: a.message || c
  };
}, wh = [];
function p(e, t) {
  const r = ht({
    issueData: t,
    data: e.data,
    path: e.path,
    errorMaps: [
      e.common.contextualErrorMap,
      e.schemaErrorMap,
      pt(),
      je
      // then global default map
    ].filter((n) => !!n)
  });
  e.common.issues.push(r);
}
class I {
  constructor() {
    this.value = "valid";
  }
  dirty() {
    this.value === "valid" && (this.value = "dirty");
  }
  abort() {
    this.value !== "aborted" && (this.value = "aborted");
  }
  static mergeArray(t, r) {
    const n = [];
    for (const a of r) {
      if (a.status === "aborted")
        return g;
      a.status === "dirty" && t.dirty(), n.push(a.value);
    }
    return { status: t.value, value: n };
  }
  static async mergeObjectAsync(t, r) {
    const n = [];
    for (const a of r)
      n.push({
        key: await a.key,
        value: await a.value
      });
    return I.mergeObjectSync(t, n);
  }
  static mergeObjectSync(t, r) {
    const n = {};
    for (const a of r) {
      const { key: s, value: o } = a;
      if (s.status === "aborted" || o.status === "aborted")
        return g;
      s.status === "dirty" && t.dirty(), o.status === "dirty" && t.dirty(), s.value !== "__proto__" && (typeof o.value < "u" || a.alwaysSet) && (n[s.value] = o.value);
    }
    return { status: t.value, value: n };
  }
}
const g = Object.freeze({
  status: "aborted"
}), ma = (e) => ({ status: "dirty", value: e }), P = (e) => ({ status: "valid", value: e }), nr = (e) => e.status === "aborted", ar = (e) => e.status === "dirty", Oe = (e) => e.status === "valid", vt = (e) => typeof Promise < "u" && e instanceof Promise;
var v;
(function(e) {
  e.errToObj = (t) => typeof t == "string" ? { message: t } : t || {}, e.toString = (t) => typeof t == "string" ? t : t == null ? void 0 : t.message;
})(v || (v = {}));
class H {
  constructor(t, r, n, a) {
    this._cachedPath = [], this.parent = t, this.data = r, this._path = n, this._key = a;
  }
  get path() {
    return this._cachedPath.length || (this._key instanceof Array ? this._cachedPath.push(...this._path, ...this._key) : this._cachedPath.push(...this._path, this._key)), this._cachedPath;
  }
}
const gn = (e, t) => {
  if (Oe(t))
    return { success: !0, data: t.value };
  if (!e.common.issues.length)
    throw new Error("Validation failed but no issues detected.");
  return {
    success: !1,
    get error() {
      if (this._error)
        return this._error;
      const r = new D(e.common.issues);
      return this._error = r, this._error;
    }
  };
};
function _(e) {
  if (!e)
    return {};
  const { errorMap: t, invalid_type_error: r, required_error: n, description: a } = e;
  if (t && (r || n))
    throw new Error(`Can't use "invalid_type_error" or "required_error" in conjunction with custom error map.`);
  return t ? { errorMap: t, description: a } : { errorMap: (o, c) => o.code !== "invalid_type" ? { message: c.defaultError } : typeof c.data > "u" ? { message: n ?? c.defaultError } : { message: r ?? c.defaultError }, description: a };
}
class b {
  constructor(t) {
    this.spa = this.safeParseAsync, this._def = t, this.parse = this.parse.bind(this), this.safeParse = this.safeParse.bind(this), this.parseAsync = this.parseAsync.bind(this), this.safeParseAsync = this.safeParseAsync.bind(this), this.spa = this.spa.bind(this), this.refine = this.refine.bind(this), this.refinement = this.refinement.bind(this), this.superRefine = this.superRefine.bind(this), this.optional = this.optional.bind(this), this.nullable = this.nullable.bind(this), this.nullish = this.nullish.bind(this), this.array = this.array.bind(this), this.promise = this.promise.bind(this), this.or = this.or.bind(this), this.and = this.and.bind(this), this.transform = this.transform.bind(this), this.brand = this.brand.bind(this), this.default = this.default.bind(this), this.catch = this.catch.bind(this), this.describe = this.describe.bind(this), this.pipe = this.pipe.bind(this), this.readonly = this.readonly.bind(this), this.isNullable = this.isNullable.bind(this), this.isOptional = this.isOptional.bind(this);
  }
  get description() {
    return this._def.description;
  }
  _getType(t) {
    return Q(t.data);
  }
  _getOrReturnCtx(t, r) {
    return r || {
      common: t.parent.common,
      data: t.data,
      parsedType: Q(t.data),
      schemaErrorMap: this._def.errorMap,
      path: t.path,
      parent: t.parent
    };
  }
  _processInputParams(t) {
    return {
      status: new I(),
      ctx: {
        common: t.parent.common,
        data: t.data,
        parsedType: Q(t.data),
        schemaErrorMap: this._def.errorMap,
        path: t.path,
        parent: t.parent
      }
    };
  }
  _parseSync(t) {
    const r = this._parse(t);
    if (vt(r))
      throw new Error("Synchronous parse encountered promise.");
    return r;
  }
  _parseAsync(t) {
    const r = this._parse(t);
    return Promise.resolve(r);
  }
  parse(t, r) {
    const n = this.safeParse(t, r);
    if (n.success)
      return n.data;
    throw n.error;
  }
  safeParse(t, r) {
    var n;
    const a = {
      common: {
        issues: [],
        async: (n = r == null ? void 0 : r.async) !== null && n !== void 0 ? n : !1,
        contextualErrorMap: r == null ? void 0 : r.errorMap
      },
      path: (r == null ? void 0 : r.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data: t,
      parsedType: Q(t)
    }, s = this._parseSync({ data: t, path: a.path, parent: a });
    return gn(a, s);
  }
  async parseAsync(t, r) {
    const n = await this.safeParseAsync(t, r);
    if (n.success)
      return n.data;
    throw n.error;
  }
  async safeParseAsync(t, r) {
    const n = {
      common: {
        issues: [],
        contextualErrorMap: r == null ? void 0 : r.errorMap,
        async: !0
      },
      path: (r == null ? void 0 : r.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data: t,
      parsedType: Q(t)
    }, a = this._parse({ data: t, path: n.path, parent: n }), s = await (vt(a) ? a : Promise.resolve(a));
    return gn(n, s);
  }
  refine(t, r) {
    const n = (a) => typeof r == "string" || typeof r > "u" ? { message: r } : typeof r == "function" ? r(a) : r;
    return this._refinement((a, s) => {
      const o = t(a), c = () => s.addIssue({
        code: d.custom,
        ...n(a)
      });
      return typeof Promise < "u" && o instanceof Promise ? o.then((l) => l ? !0 : (c(), !1)) : o ? !0 : (c(), !1);
    });
  }
  refinement(t, r) {
    return this._refinement((n, a) => t(n) ? !0 : (a.addIssue(typeof r == "function" ? r(n, a) : r), !1));
  }
  _refinement(t) {
    return new U({
      schema: this,
      typeName: y.ZodEffects,
      effect: { type: "refinement", refinement: t }
    });
  }
  superRefine(t) {
    return this._refinement(t);
  }
  optional() {
    return z.create(this, this._def);
  }
  nullable() {
    return ce.create(this, this._def);
  }
  nullish() {
    return this.nullable().optional();
  }
  array() {
    return Z.create(this, this._def);
  }
  promise() {
    return ve.create(this, this._def);
  }
  or(t) {
    return Ie.create([this, t], this._def);
  }
  and(t) {
    return Ne.create(this, t, this._def);
  }
  transform(t) {
    return new U({
      ..._(this._def),
      schema: this,
      typeName: y.ZodEffects,
      effect: { type: "transform", transform: t }
    });
  }
  default(t) {
    const r = typeof t == "function" ? t : () => t;
    return new De({
      ..._(this._def),
      innerType: this,
      defaultValue: r,
      typeName: y.ZodDefault
    });
  }
  brand() {
    return new _a({
      typeName: y.ZodBranded,
      type: this,
      ..._(this._def)
    });
  }
  catch(t) {
    const r = typeof t == "function" ? t : () => t;
    return new _t({
      ..._(this._def),
      innerType: this,
      catchValue: r,
      typeName: y.ZodCatch
    });
  }
  describe(t) {
    const r = this.constructor;
    return new r({
      ...this._def,
      description: t
    });
  }
  pipe(t) {
    return He.create(this, t);
  }
  readonly() {
    return Tt.create(this);
  }
  isOptional() {
    return this.safeParse(void 0).success;
  }
  isNullable() {
    return this.safeParse(null).success;
  }
}
const xh = /^c[^\s-]{8,}$/i, jh = /^[a-z][a-z0-9]*$/, Oh = /^[0-9A-HJKMNP-TV-Z]{26}$/, kh = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i, Rh = /^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i, Ch = "^(\\p{Extended_Pictographic}|\\p{Emoji_Component})+$";
let Kt;
const Ih = /^(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))$/, Nh = /^(([a-f0-9]{1,4}:){7}|::([a-f0-9]{1,4}:){0,6}|([a-f0-9]{1,4}:){1}:([a-f0-9]{1,4}:){0,5}|([a-f0-9]{1,4}:){2}:([a-f0-9]{1,4}:){0,4}|([a-f0-9]{1,4}:){3}:([a-f0-9]{1,4}:){0,3}|([a-f0-9]{1,4}:){4}:([a-f0-9]{1,4}:){0,2}|([a-f0-9]{1,4}:){5}:([a-f0-9]{1,4}:){0,1})([a-f0-9]{1,4}|(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2})))$/, Ph = (e) => e.precision ? e.offset ? new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{${e.precision}}(([+-]\\d{2}(:?\\d{2})?)|Z)$`) : new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{${e.precision}}Z$`) : e.precision === 0 ? e.offset ? new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(([+-]\\d{2}(:?\\d{2})?)|Z)$") : new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}Z$") : e.offset ? new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?(([+-]\\d{2}(:?\\d{2})?)|Z)$") : new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?Z$");
function Vh(e, t) {
  return !!((t === "v4" || !t) && Ih.test(e) || (t === "v6" || !t) && Nh.test(e));
}
class L extends b {
  _parse(t) {
    if (this._def.coerce && (t.data = String(t.data)), this._getType(t) !== f.string) {
      const s = this._getOrReturnCtx(t);
      return p(
        s,
        {
          code: d.invalid_type,
          expected: f.string,
          received: s.parsedType
        }
        //
      ), g;
    }
    const n = new I();
    let a;
    for (const s of this._def.checks)
      if (s.kind === "min")
        t.data.length < s.value && (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.too_small,
          minimum: s.value,
          type: "string",
          inclusive: !0,
          exact: !1,
          message: s.message
        }), n.dirty());
      else if (s.kind === "max")
        t.data.length > s.value && (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.too_big,
          maximum: s.value,
          type: "string",
          inclusive: !0,
          exact: !1,
          message: s.message
        }), n.dirty());
      else if (s.kind === "length") {
        const o = t.data.length > s.value, c = t.data.length < s.value;
        (o || c) && (a = this._getOrReturnCtx(t, a), o ? p(a, {
          code: d.too_big,
          maximum: s.value,
          type: "string",
          inclusive: !0,
          exact: !0,
          message: s.message
        }) : c && p(a, {
          code: d.too_small,
          minimum: s.value,
          type: "string",
          inclusive: !0,
          exact: !0,
          message: s.message
        }), n.dirty());
      } else if (s.kind === "email")
        Rh.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "email",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "emoji")
        Kt || (Kt = new RegExp(Ch, "u")), Kt.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "emoji",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "uuid")
        kh.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "uuid",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "cuid")
        xh.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "cuid",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "cuid2")
        jh.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "cuid2",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "ulid")
        Oh.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "ulid",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "url")
        try {
          new URL(t.data);
        } catch {
          a = this._getOrReturnCtx(t, a), p(a, {
            validation: "url",
            code: d.invalid_string,
            message: s.message
          }), n.dirty();
        }
      else
        s.kind === "regex" ? (s.regex.lastIndex = 0, s.regex.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "regex",
          code: d.invalid_string,
          message: s.message
        }), n.dirty())) : s.kind === "trim" ? t.data = t.data.trim() : s.kind === "includes" ? t.data.includes(s.value, s.position) || (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.invalid_string,
          validation: { includes: s.value, position: s.position },
          message: s.message
        }), n.dirty()) : s.kind === "toLowerCase" ? t.data = t.data.toLowerCase() : s.kind === "toUpperCase" ? t.data = t.data.toUpperCase() : s.kind === "startsWith" ? t.data.startsWith(s.value) || (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.invalid_string,
          validation: { startsWith: s.value },
          message: s.message
        }), n.dirty()) : s.kind === "endsWith" ? t.data.endsWith(s.value) || (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.invalid_string,
          validation: { endsWith: s.value },
          message: s.message
        }), n.dirty()) : s.kind === "datetime" ? Ph(s).test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.invalid_string,
          validation: "datetime",
          message: s.message
        }), n.dirty()) : s.kind === "ip" ? Vh(t.data, s.version) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "ip",
          code: d.invalid_string,
          message: s.message
        }), n.dirty()) : $.assertNever(s);
    return { status: n.value, value: t.data };
  }
  _regex(t, r, n) {
    return this.refinement((a) => t.test(a), {
      validation: r,
      code: d.invalid_string,
      ...v.errToObj(n)
    });
  }
  _addCheck(t) {
    return new L({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  email(t) {
    return this._addCheck({ kind: "email", ...v.errToObj(t) });
  }
  url(t) {
    return this._addCheck({ kind: "url", ...v.errToObj(t) });
  }
  emoji(t) {
    return this._addCheck({ kind: "emoji", ...v.errToObj(t) });
  }
  uuid(t) {
    return this._addCheck({ kind: "uuid", ...v.errToObj(t) });
  }
  cuid(t) {
    return this._addCheck({ kind: "cuid", ...v.errToObj(t) });
  }
  cuid2(t) {
    return this._addCheck({ kind: "cuid2", ...v.errToObj(t) });
  }
  ulid(t) {
    return this._addCheck({ kind: "ulid", ...v.errToObj(t) });
  }
  ip(t) {
    return this._addCheck({ kind: "ip", ...v.errToObj(t) });
  }
  datetime(t) {
    var r;
    return typeof t == "string" ? this._addCheck({
      kind: "datetime",
      precision: null,
      offset: !1,
      message: t
    }) : this._addCheck({
      kind: "datetime",
      precision: typeof (t == null ? void 0 : t.precision) > "u" ? null : t == null ? void 0 : t.precision,
      offset: (r = t == null ? void 0 : t.offset) !== null && r !== void 0 ? r : !1,
      ...v.errToObj(t == null ? void 0 : t.message)
    });
  }
  regex(t, r) {
    return this._addCheck({
      kind: "regex",
      regex: t,
      ...v.errToObj(r)
    });
  }
  includes(t, r) {
    return this._addCheck({
      kind: "includes",
      value: t,
      position: r == null ? void 0 : r.position,
      ...v.errToObj(r == null ? void 0 : r.message)
    });
  }
  startsWith(t, r) {
    return this._addCheck({
      kind: "startsWith",
      value: t,
      ...v.errToObj(r)
    });
  }
  endsWith(t, r) {
    return this._addCheck({
      kind: "endsWith",
      value: t,
      ...v.errToObj(r)
    });
  }
  min(t, r) {
    return this._addCheck({
      kind: "min",
      value: t,
      ...v.errToObj(r)
    });
  }
  max(t, r) {
    return this._addCheck({
      kind: "max",
      value: t,
      ...v.errToObj(r)
    });
  }
  length(t, r) {
    return this._addCheck({
      kind: "length",
      value: t,
      ...v.errToObj(r)
    });
  }
  /**
   * @deprecated Use z.string().min(1) instead.
   * @see {@link ZodString.min}
   */
  nonempty(t) {
    return this.min(1, v.errToObj(t));
  }
  trim() {
    return new L({
      ...this._def,
      checks: [...this._def.checks, { kind: "trim" }]
    });
  }
  toLowerCase() {
    return new L({
      ...this._def,
      checks: [...this._def.checks, { kind: "toLowerCase" }]
    });
  }
  toUpperCase() {
    return new L({
      ...this._def,
      checks: [...this._def.checks, { kind: "toUpperCase" }]
    });
  }
  get isDatetime() {
    return !!this._def.checks.find((t) => t.kind === "datetime");
  }
  get isEmail() {
    return !!this._def.checks.find((t) => t.kind === "email");
  }
  get isURL() {
    return !!this._def.checks.find((t) => t.kind === "url");
  }
  get isEmoji() {
    return !!this._def.checks.find((t) => t.kind === "emoji");
  }
  get isUUID() {
    return !!this._def.checks.find((t) => t.kind === "uuid");
  }
  get isCUID() {
    return !!this._def.checks.find((t) => t.kind === "cuid");
  }
  get isCUID2() {
    return !!this._def.checks.find((t) => t.kind === "cuid2");
  }
  get isULID() {
    return !!this._def.checks.find((t) => t.kind === "ulid");
  }
  get isIP() {
    return !!this._def.checks.find((t) => t.kind === "ip");
  }
  get minLength() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxLength() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
}
L.create = (e) => {
  var t;
  return new L({
    checks: [],
    typeName: y.ZodString,
    coerce: (t = e == null ? void 0 : e.coerce) !== null && t !== void 0 ? t : !1,
    ..._(e)
  });
};
function Mh(e, t) {
  const r = (e.toString().split(".")[1] || "").length, n = (t.toString().split(".")[1] || "").length, a = r > n ? r : n, s = parseInt(e.toFixed(a).replace(".", "")), o = parseInt(t.toFixed(a).replace(".", ""));
  return s % o / Math.pow(10, a);
}
class Y extends b {
  constructor() {
    super(...arguments), this.min = this.gte, this.max = this.lte, this.step = this.multipleOf;
  }
  _parse(t) {
    if (this._def.coerce && (t.data = Number(t.data)), this._getType(t) !== f.number) {
      const s = this._getOrReturnCtx(t);
      return p(s, {
        code: d.invalid_type,
        expected: f.number,
        received: s.parsedType
      }), g;
    }
    let n;
    const a = new I();
    for (const s of this._def.checks)
      s.kind === "int" ? $.isInteger(t.data) || (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.invalid_type,
        expected: "integer",
        received: "float",
        message: s.message
      }), a.dirty()) : s.kind === "min" ? (s.inclusive ? t.data < s.value : t.data <= s.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_small,
        minimum: s.value,
        type: "number",
        inclusive: s.inclusive,
        exact: !1,
        message: s.message
      }), a.dirty()) : s.kind === "max" ? (s.inclusive ? t.data > s.value : t.data >= s.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_big,
        maximum: s.value,
        type: "number",
        inclusive: s.inclusive,
        exact: !1,
        message: s.message
      }), a.dirty()) : s.kind === "multipleOf" ? Mh(t.data, s.value) !== 0 && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.not_multiple_of,
        multipleOf: s.value,
        message: s.message
      }), a.dirty()) : s.kind === "finite" ? Number.isFinite(t.data) || (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.not_finite,
        message: s.message
      }), a.dirty()) : $.assertNever(s);
    return { status: a.value, value: t.data };
  }
  gte(t, r) {
    return this.setLimit("min", t, !0, v.toString(r));
  }
  gt(t, r) {
    return this.setLimit("min", t, !1, v.toString(r));
  }
  lte(t, r) {
    return this.setLimit("max", t, !0, v.toString(r));
  }
  lt(t, r) {
    return this.setLimit("max", t, !1, v.toString(r));
  }
  setLimit(t, r, n, a) {
    return new Y({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind: t,
          value: r,
          inclusive: n,
          message: v.toString(a)
        }
      ]
    });
  }
  _addCheck(t) {
    return new Y({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  int(t) {
    return this._addCheck({
      kind: "int",
      message: v.toString(t)
    });
  }
  positive(t) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: !1,
      message: v.toString(t)
    });
  }
  negative(t) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: !1,
      message: v.toString(t)
    });
  }
  nonpositive(t) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: !0,
      message: v.toString(t)
    });
  }
  nonnegative(t) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: !0,
      message: v.toString(t)
    });
  }
  multipleOf(t, r) {
    return this._addCheck({
      kind: "multipleOf",
      value: t,
      message: v.toString(r)
    });
  }
  finite(t) {
    return this._addCheck({
      kind: "finite",
      message: v.toString(t)
    });
  }
  safe(t) {
    return this._addCheck({
      kind: "min",
      inclusive: !0,
      value: Number.MIN_SAFE_INTEGER,
      message: v.toString(t)
    })._addCheck({
      kind: "max",
      inclusive: !0,
      value: Number.MAX_SAFE_INTEGER,
      message: v.toString(t)
    });
  }
  get minValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
  get isInt() {
    return !!this._def.checks.find((t) => t.kind === "int" || t.kind === "multipleOf" && $.isInteger(t.value));
  }
  get isFinite() {
    let t = null, r = null;
    for (const n of this._def.checks) {
      if (n.kind === "finite" || n.kind === "int" || n.kind === "multipleOf")
        return !0;
      n.kind === "min" ? (r === null || n.value > r) && (r = n.value) : n.kind === "max" && (t === null || n.value < t) && (t = n.value);
    }
    return Number.isFinite(r) && Number.isFinite(t);
  }
}
Y.create = (e) => new Y({
  checks: [],
  typeName: y.ZodNumber,
  coerce: (e == null ? void 0 : e.coerce) || !1,
  ..._(e)
});
class J extends b {
  constructor() {
    super(...arguments), this.min = this.gte, this.max = this.lte;
  }
  _parse(t) {
    if (this._def.coerce && (t.data = BigInt(t.data)), this._getType(t) !== f.bigint) {
      const s = this._getOrReturnCtx(t);
      return p(s, {
        code: d.invalid_type,
        expected: f.bigint,
        received: s.parsedType
      }), g;
    }
    let n;
    const a = new I();
    for (const s of this._def.checks)
      s.kind === "min" ? (s.inclusive ? t.data < s.value : t.data <= s.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_small,
        type: "bigint",
        minimum: s.value,
        inclusive: s.inclusive,
        message: s.message
      }), a.dirty()) : s.kind === "max" ? (s.inclusive ? t.data > s.value : t.data >= s.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_big,
        type: "bigint",
        maximum: s.value,
        inclusive: s.inclusive,
        message: s.message
      }), a.dirty()) : s.kind === "multipleOf" ? t.data % s.value !== BigInt(0) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.not_multiple_of,
        multipleOf: s.value,
        message: s.message
      }), a.dirty()) : $.assertNever(s);
    return { status: a.value, value: t.data };
  }
  gte(t, r) {
    return this.setLimit("min", t, !0, v.toString(r));
  }
  gt(t, r) {
    return this.setLimit("min", t, !1, v.toString(r));
  }
  lte(t, r) {
    return this.setLimit("max", t, !0, v.toString(r));
  }
  lt(t, r) {
    return this.setLimit("max", t, !1, v.toString(r));
  }
  setLimit(t, r, n, a) {
    return new J({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind: t,
          value: r,
          inclusive: n,
          message: v.toString(a)
        }
      ]
    });
  }
  _addCheck(t) {
    return new J({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  positive(t) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: !1,
      message: v.toString(t)
    });
  }
  negative(t) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: !1,
      message: v.toString(t)
    });
  }
  nonpositive(t) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: !0,
      message: v.toString(t)
    });
  }
  nonnegative(t) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: !0,
      message: v.toString(t)
    });
  }
  multipleOf(t, r) {
    return this._addCheck({
      kind: "multipleOf",
      value: t,
      message: v.toString(r)
    });
  }
  get minValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
}
J.create = (e) => {
  var t;
  return new J({
    checks: [],
    typeName: y.ZodBigInt,
    coerce: (t = e == null ? void 0 : e.coerce) !== null && t !== void 0 ? t : !1,
    ..._(e)
  });
};
class ke extends b {
  _parse(t) {
    if (this._def.coerce && (t.data = !!t.data), this._getType(t) !== f.boolean) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.boolean,
        received: n.parsedType
      }), g;
    }
    return P(t.data);
  }
}
ke.create = (e) => new ke({
  typeName: y.ZodBoolean,
  coerce: (e == null ? void 0 : e.coerce) || !1,
  ..._(e)
});
class ie extends b {
  _parse(t) {
    if (this._def.coerce && (t.data = new Date(t.data)), this._getType(t) !== f.date) {
      const s = this._getOrReturnCtx(t);
      return p(s, {
        code: d.invalid_type,
        expected: f.date,
        received: s.parsedType
      }), g;
    }
    if (isNaN(t.data.getTime())) {
      const s = this._getOrReturnCtx(t);
      return p(s, {
        code: d.invalid_date
      }), g;
    }
    const n = new I();
    let a;
    for (const s of this._def.checks)
      s.kind === "min" ? t.data.getTime() < s.value && (a = this._getOrReturnCtx(t, a), p(a, {
        code: d.too_small,
        message: s.message,
        inclusive: !0,
        exact: !1,
        minimum: s.value,
        type: "date"
      }), n.dirty()) : s.kind === "max" ? t.data.getTime() > s.value && (a = this._getOrReturnCtx(t, a), p(a, {
        code: d.too_big,
        message: s.message,
        inclusive: !0,
        exact: !1,
        maximum: s.value,
        type: "date"
      }), n.dirty()) : $.assertNever(s);
    return {
      status: n.value,
      value: new Date(t.data.getTime())
    };
  }
  _addCheck(t) {
    return new ie({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  min(t, r) {
    return this._addCheck({
      kind: "min",
      value: t.getTime(),
      message: v.toString(r)
    });
  }
  max(t, r) {
    return this._addCheck({
      kind: "max",
      value: t.getTime(),
      message: v.toString(r)
    });
  }
  get minDate() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t != null ? new Date(t) : null;
  }
  get maxDate() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t != null ? new Date(t) : null;
  }
}
ie.create = (e) => new ie({
  checks: [],
  coerce: (e == null ? void 0 : e.coerce) || !1,
  typeName: y.ZodDate,
  ..._(e)
});
class yt extends b {
  _parse(t) {
    if (this._getType(t) !== f.symbol) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.symbol,
        received: n.parsedType
      }), g;
    }
    return P(t.data);
  }
}
yt.create = (e) => new yt({
  typeName: y.ZodSymbol,
  ..._(e)
});
class Re extends b {
  _parse(t) {
    if (this._getType(t) !== f.undefined) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.undefined,
        received: n.parsedType
      }), g;
    }
    return P(t.data);
  }
}
Re.create = (e) => new Re({
  typeName: y.ZodUndefined,
  ..._(e)
});
class Ce extends b {
  _parse(t) {
    if (this._getType(t) !== f.null) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.null,
        received: n.parsedType
      }), g;
    }
    return P(t.data);
  }
}
Ce.create = (e) => new Ce({
  typeName: y.ZodNull,
  ..._(e)
});
class he extends b {
  constructor() {
    super(...arguments), this._any = !0;
  }
  _parse(t) {
    return P(t.data);
  }
}
he.create = (e) => new he({
  typeName: y.ZodAny,
  ..._(e)
});
class se extends b {
  constructor() {
    super(...arguments), this._unknown = !0;
  }
  _parse(t) {
    return P(t.data);
  }
}
se.create = (e) => new se({
  typeName: y.ZodUnknown,
  ..._(e)
});
class K extends b {
  _parse(t) {
    const r = this._getOrReturnCtx(t);
    return p(r, {
      code: d.invalid_type,
      expected: f.never,
      received: r.parsedType
    }), g;
  }
}
K.create = (e) => new K({
  typeName: y.ZodNever,
  ..._(e)
});
class mt extends b {
  _parse(t) {
    if (this._getType(t) !== f.undefined) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.void,
        received: n.parsedType
      }), g;
    }
    return P(t.data);
  }
}
mt.create = (e) => new mt({
  typeName: y.ZodVoid,
  ..._(e)
});
class Z extends b {
  _parse(t) {
    const { ctx: r, status: n } = this._processInputParams(t), a = this._def;
    if (r.parsedType !== f.array)
      return p(r, {
        code: d.invalid_type,
        expected: f.array,
        received: r.parsedType
      }), g;
    if (a.exactLength !== null) {
      const o = r.data.length > a.exactLength.value, c = r.data.length < a.exactLength.value;
      (o || c) && (p(r, {
        code: o ? d.too_big : d.too_small,
        minimum: c ? a.exactLength.value : void 0,
        maximum: o ? a.exactLength.value : void 0,
        type: "array",
        inclusive: !0,
        exact: !0,
        message: a.exactLength.message
      }), n.dirty());
    }
    if (a.minLength !== null && r.data.length < a.minLength.value && (p(r, {
      code: d.too_small,
      minimum: a.minLength.value,
      type: "array",
      inclusive: !0,
      exact: !1,
      message: a.minLength.message
    }), n.dirty()), a.maxLength !== null && r.data.length > a.maxLength.value && (p(r, {
      code: d.too_big,
      maximum: a.maxLength.value,
      type: "array",
      inclusive: !0,
      exact: !1,
      message: a.maxLength.message
    }), n.dirty()), r.common.async)
      return Promise.all([...r.data].map((o, c) => a.type._parseAsync(new H(r, o, r.path, c)))).then((o) => I.mergeArray(n, o));
    const s = [...r.data].map((o, c) => a.type._parseSync(new H(r, o, r.path, c)));
    return I.mergeArray(n, s);
  }
  get element() {
    return this._def.type;
  }
  min(t, r) {
    return new Z({
      ...this._def,
      minLength: { value: t, message: v.toString(r) }
    });
  }
  max(t, r) {
    return new Z({
      ...this._def,
      maxLength: { value: t, message: v.toString(r) }
    });
  }
  length(t, r) {
    return new Z({
      ...this._def,
      exactLength: { value: t, message: v.toString(r) }
    });
  }
  nonempty(t) {
    return this.min(1, t);
  }
}
Z.create = (e, t) => new Z({
  type: e,
  minLength: null,
  maxLength: null,
  exactLength: null,
  typeName: y.ZodArray,
  ..._(t)
});
function fe(e) {
  if (e instanceof w) {
    const t = {};
    for (const r in e.shape) {
      const n = e.shape[r];
      t[r] = z.create(fe(n));
    }
    return new w({
      ...e._def,
      shape: () => t
    });
  } else
    return e instanceof Z ? new Z({
      ...e._def,
      type: fe(e.element)
    }) : e instanceof z ? z.create(fe(e.unwrap())) : e instanceof ce ? ce.create(fe(e.unwrap())) : e instanceof q ? q.create(e.items.map((t) => fe(t))) : e;
}
class w extends b {
  constructor() {
    super(...arguments), this._cached = null, this.nonstrict = this.passthrough, this.augment = this.extend;
  }
  _getCached() {
    if (this._cached !== null)
      return this._cached;
    const t = this._def.shape(), r = $.objectKeys(t);
    return this._cached = { shape: t, keys: r };
  }
  _parse(t) {
    if (this._getType(t) !== f.object) {
      const u = this._getOrReturnCtx(t);
      return p(u, {
        code: d.invalid_type,
        expected: f.object,
        received: u.parsedType
      }), g;
    }
    const { status: n, ctx: a } = this._processInputParams(t), { shape: s, keys: o } = this._getCached(), c = [];
    if (!(this._def.catchall instanceof K && this._def.unknownKeys === "strip"))
      for (const u in a.data)
        o.includes(u) || c.push(u);
    const l = [];
    for (const u of o) {
      const h = s[u], T = a.data[u];
      l.push({
        key: { status: "valid", value: u },
        value: h._parse(new H(a, T, a.path, u)),
        alwaysSet: u in a.data
      });
    }
    if (this._def.catchall instanceof K) {
      const u = this._def.unknownKeys;
      if (u === "passthrough")
        for (const h of c)
          l.push({
            key: { status: "valid", value: h },
            value: { status: "valid", value: a.data[h] }
          });
      else if (u === "strict")
        c.length > 0 && (p(a, {
          code: d.unrecognized_keys,
          keys: c
        }), n.dirty());
      else if (u !== "strip")
        throw new Error("Internal ZodObject error: invalid unknownKeys value.");
    } else {
      const u = this._def.catchall;
      for (const h of c) {
        const T = a.data[h];
        l.push({
          key: { status: "valid", value: h },
          value: u._parse(
            new H(a, T, a.path, h)
            //, ctx.child(key), value, getParsedType(value)
          ),
          alwaysSet: h in a.data
        });
      }
    }
    return a.common.async ? Promise.resolve().then(async () => {
      const u = [];
      for (const h of l) {
        const T = await h.key;
        u.push({
          key: T,
          value: await h.value,
          alwaysSet: h.alwaysSet
        });
      }
      return u;
    }).then((u) => I.mergeObjectSync(n, u)) : I.mergeObjectSync(n, l);
  }
  get shape() {
    return this._def.shape();
  }
  strict(t) {
    return v.errToObj, new w({
      ...this._def,
      unknownKeys: "strict",
      ...t !== void 0 ? {
        errorMap: (r, n) => {
          var a, s, o, c;
          const l = (o = (s = (a = this._def).errorMap) === null || s === void 0 ? void 0 : s.call(a, r, n).message) !== null && o !== void 0 ? o : n.defaultError;
          return r.code === "unrecognized_keys" ? {
            message: (c = v.errToObj(t).message) !== null && c !== void 0 ? c : l
          } : {
            message: l
          };
        }
      } : {}
    });
  }
  strip() {
    return new w({
      ...this._def,
      unknownKeys: "strip"
    });
  }
  passthrough() {
    return new w({
      ...this._def,
      unknownKeys: "passthrough"
    });
  }
  // const AugmentFactory =
  //   <Def extends ZodObjectDef>(def: Def) =>
  //   <Augmentation extends ZodRawShape>(
  //     augmentation: Augmentation
  //   ): ZodObject<
  //     extendShape<ReturnType<Def["shape"]>, Augmentation>,
  //     Def["unknownKeys"],
  //     Def["catchall"]
  //   > => {
  //     return new ZodObject({
  //       ...def,
  //       shape: () => ({
  //         ...def.shape(),
  //         ...augmentation,
  //       }),
  //     }) as any;
  //   };
  extend(t) {
    return new w({
      ...this._def,
      shape: () => ({
        ...this._def.shape(),
        ...t
      })
    });
  }
  /**
   * Prior to zod@1.0.12 there was a bug in the
   * inferred type of merged objects. Please
   * upgrade if you are experiencing issues.
   */
  merge(t) {
    return new w({
      unknownKeys: t._def.unknownKeys,
      catchall: t._def.catchall,
      shape: () => ({
        ...this._def.shape(),
        ...t._def.shape()
      }),
      typeName: y.ZodObject
    });
  }
  // merge<
  //   Incoming extends AnyZodObject,
  //   Augmentation extends Incoming["shape"],
  //   NewOutput extends {
  //     [k in keyof Augmentation | keyof Output]: k extends keyof Augmentation
  //       ? Augmentation[k]["_output"]
  //       : k extends keyof Output
  //       ? Output[k]
  //       : never;
  //   },
  //   NewInput extends {
  //     [k in keyof Augmentation | keyof Input]: k extends keyof Augmentation
  //       ? Augmentation[k]["_input"]
  //       : k extends keyof Input
  //       ? Input[k]
  //       : never;
  //   }
  // >(
  //   merging: Incoming
  // ): ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"],
  //   NewOutput,
  //   NewInput
  // > {
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  setKey(t, r) {
    return this.augment({ [t]: r });
  }
  // merge<Incoming extends AnyZodObject>(
  //   merging: Incoming
  // ): //ZodObject<T & Incoming["_shape"], UnknownKeys, Catchall> = (merging) => {
  // ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"]
  // > {
  //   // const mergedShape = objectUtil.mergeShapes(
  //   //   this._def.shape(),
  //   //   merging._def.shape()
  //   // );
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  catchall(t) {
    return new w({
      ...this._def,
      catchall: t
    });
  }
  pick(t) {
    const r = {};
    return $.objectKeys(t).forEach((n) => {
      t[n] && this.shape[n] && (r[n] = this.shape[n]);
    }), new w({
      ...this._def,
      shape: () => r
    });
  }
  omit(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      t[n] || (r[n] = this.shape[n]);
    }), new w({
      ...this._def,
      shape: () => r
    });
  }
  /**
   * @deprecated
   */
  deepPartial() {
    return fe(this);
  }
  partial(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      const a = this.shape[n];
      t && !t[n] ? r[n] = a : r[n] = a.optional();
    }), new w({
      ...this._def,
      shape: () => r
    });
  }
  required(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      if (t && !t[n])
        r[n] = this.shape[n];
      else {
        let s = this.shape[n];
        for (; s instanceof z; )
          s = s._def.innerType;
        r[n] = s;
      }
    }), new w({
      ...this._def,
      shape: () => r
    });
  }
  keyof() {
    return ga($.objectKeys(this.shape));
  }
}
w.create = (e, t) => new w({
  shape: () => e,
  unknownKeys: "strip",
  catchall: K.create(),
  typeName: y.ZodObject,
  ..._(t)
});
w.strictCreate = (e, t) => new w({
  shape: () => e,
  unknownKeys: "strict",
  catchall: K.create(),
  typeName: y.ZodObject,
  ..._(t)
});
w.lazycreate = (e, t) => new w({
  shape: e,
  unknownKeys: "strip",
  catchall: K.create(),
  typeName: y.ZodObject,
  ..._(t)
});
class Ie extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = this._def.options;
    function a(s) {
      for (const c of s)
        if (c.result.status === "valid")
          return c.result;
      for (const c of s)
        if (c.result.status === "dirty")
          return r.common.issues.push(...c.ctx.common.issues), c.result;
      const o = s.map((c) => new D(c.ctx.common.issues));
      return p(r, {
        code: d.invalid_union,
        unionErrors: o
      }), g;
    }
    if (r.common.async)
      return Promise.all(n.map(async (s) => {
        const o = {
          ...r,
          common: {
            ...r.common,
            issues: []
          },
          parent: null
        };
        return {
          result: await s._parseAsync({
            data: r.data,
            path: r.path,
            parent: o
          }),
          ctx: o
        };
      })).then(a);
    {
      let s;
      const o = [];
      for (const l of n) {
        const u = {
          ...r,
          common: {
            ...r.common,
            issues: []
          },
          parent: null
        }, h = l._parseSync({
          data: r.data,
          path: r.path,
          parent: u
        });
        if (h.status === "valid")
          return h;
        h.status === "dirty" && !s && (s = { result: h, ctx: u }), u.common.issues.length && o.push(u.common.issues);
      }
      if (s)
        return r.common.issues.push(...s.ctx.common.issues), s.result;
      const c = o.map((l) => new D(l));
      return p(r, {
        code: d.invalid_union,
        unionErrors: c
      }), g;
    }
  }
  get options() {
    return this._def.options;
  }
}
Ie.create = (e, t) => new Ie({
  options: e,
  typeName: y.ZodUnion,
  ..._(t)
});
const ut = (e) => e instanceof Ve ? ut(e.schema) : e instanceof U ? ut(e.innerType()) : e instanceof Me ? [e.value] : e instanceof X ? e.options : e instanceof Le ? Object.keys(e.enum) : e instanceof De ? ut(e._def.innerType) : e instanceof Re ? [void 0] : e instanceof Ce ? [null] : null;
class Vt extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== f.object)
      return p(r, {
        code: d.invalid_type,
        expected: f.object,
        received: r.parsedType
      }), g;
    const n = this.discriminator, a = r.data[n], s = this.optionsMap.get(a);
    return s ? r.common.async ? s._parseAsync({
      data: r.data,
      path: r.path,
      parent: r
    }) : s._parseSync({
      data: r.data,
      path: r.path,
      parent: r
    }) : (p(r, {
      code: d.invalid_union_discriminator,
      options: Array.from(this.optionsMap.keys()),
      path: [n]
    }), g);
  }
  get discriminator() {
    return this._def.discriminator;
  }
  get options() {
    return this._def.options;
  }
  get optionsMap() {
    return this._def.optionsMap;
  }
  /**
   * The constructor of the discriminated union schema. Its behaviour is very similar to that of the normal z.union() constructor.
   * However, it only allows a union of objects, all of which need to share a discriminator property. This property must
   * have a different value for each object in the union.
   * @param discriminator the name of the discriminator property
   * @param types an array of object schemas
   * @param params
   */
  static create(t, r, n) {
    const a = /* @__PURE__ */ new Map();
    for (const s of r) {
      const o = ut(s.shape[t]);
      if (!o)
        throw new Error(`A discriminator value for key \`${t}\` could not be extracted from all schema options`);
      for (const c of o) {
        if (a.has(c))
          throw new Error(`Discriminator property ${String(t)} has duplicate value ${String(c)}`);
        a.set(c, s);
      }
    }
    return new Vt({
      typeName: y.ZodDiscriminatedUnion,
      discriminator: t,
      options: r,
      optionsMap: a,
      ..._(n)
    });
  }
}
function sr(e, t) {
  const r = Q(e), n = Q(t);
  if (e === t)
    return { valid: !0, data: e };
  if (r === f.object && n === f.object) {
    const a = $.objectKeys(t), s = $.objectKeys(e).filter((c) => a.indexOf(c) !== -1), o = { ...e, ...t };
    for (const c of s) {
      const l = sr(e[c], t[c]);
      if (!l.valid)
        return { valid: !1 };
      o[c] = l.data;
    }
    return { valid: !0, data: o };
  } else if (r === f.array && n === f.array) {
    if (e.length !== t.length)
      return { valid: !1 };
    const a = [];
    for (let s = 0; s < e.length; s++) {
      const o = e[s], c = t[s], l = sr(o, c);
      if (!l.valid)
        return { valid: !1 };
      a.push(l.data);
    }
    return { valid: !0, data: a };
  } else
    return r === f.date && n === f.date && +e == +t ? { valid: !0, data: e } : { valid: !1 };
}
class Ne extends b {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t), a = (s, o) => {
      if (nr(s) || nr(o))
        return g;
      const c = sr(s.value, o.value);
      return c.valid ? ((ar(s) || ar(o)) && r.dirty(), { status: r.value, value: c.data }) : (p(n, {
        code: d.invalid_intersection_types
      }), g);
    };
    return n.common.async ? Promise.all([
      this._def.left._parseAsync({
        data: n.data,
        path: n.path,
        parent: n
      }),
      this._def.right._parseAsync({
        data: n.data,
        path: n.path,
        parent: n
      })
    ]).then(([s, o]) => a(s, o)) : a(this._def.left._parseSync({
      data: n.data,
      path: n.path,
      parent: n
    }), this._def.right._parseSync({
      data: n.data,
      path: n.path,
      parent: n
    }));
  }
}
Ne.create = (e, t, r) => new Ne({
  left: e,
  right: t,
  typeName: y.ZodIntersection,
  ..._(r)
});
class q extends b {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== f.array)
      return p(n, {
        code: d.invalid_type,
        expected: f.array,
        received: n.parsedType
      }), g;
    if (n.data.length < this._def.items.length)
      return p(n, {
        code: d.too_small,
        minimum: this._def.items.length,
        inclusive: !0,
        exact: !1,
        type: "array"
      }), g;
    !this._def.rest && n.data.length > this._def.items.length && (p(n, {
      code: d.too_big,
      maximum: this._def.items.length,
      inclusive: !0,
      exact: !1,
      type: "array"
    }), r.dirty());
    const s = [...n.data].map((o, c) => {
      const l = this._def.items[c] || this._def.rest;
      return l ? l._parse(new H(n, o, n.path, c)) : null;
    }).filter((o) => !!o);
    return n.common.async ? Promise.all(s).then((o) => I.mergeArray(r, o)) : I.mergeArray(r, s);
  }
  get items() {
    return this._def.items;
  }
  rest(t) {
    return new q({
      ...this._def,
      rest: t
    });
  }
}
q.create = (e, t) => {
  if (!Array.isArray(e))
    throw new Error("You must pass an array of schemas to z.tuple([ ... ])");
  return new q({
    items: e,
    typeName: y.ZodTuple,
    rest: null,
    ..._(t)
  });
};
class Pe extends b {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== f.object)
      return p(n, {
        code: d.invalid_type,
        expected: f.object,
        received: n.parsedType
      }), g;
    const a = [], s = this._def.keyType, o = this._def.valueType;
    for (const c in n.data)
      a.push({
        key: s._parse(new H(n, c, n.path, c)),
        value: o._parse(new H(n, n.data[c], n.path, c))
      });
    return n.common.async ? I.mergeObjectAsync(r, a) : I.mergeObjectSync(r, a);
  }
  get element() {
    return this._def.valueType;
  }
  static create(t, r, n) {
    return r instanceof b ? new Pe({
      keyType: t,
      valueType: r,
      typeName: y.ZodRecord,
      ..._(n)
    }) : new Pe({
      keyType: L.create(),
      valueType: t,
      typeName: y.ZodRecord,
      ..._(r)
    });
  }
}
class gt extends b {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== f.map)
      return p(n, {
        code: d.invalid_type,
        expected: f.map,
        received: n.parsedType
      }), g;
    const a = this._def.keyType, s = this._def.valueType, o = [...n.data.entries()].map(([c, l], u) => ({
      key: a._parse(new H(n, c, n.path, [u, "key"])),
      value: s._parse(new H(n, l, n.path, [u, "value"]))
    }));
    if (n.common.async) {
      const c = /* @__PURE__ */ new Map();
      return Promise.resolve().then(async () => {
        for (const l of o) {
          const u = await l.key, h = await l.value;
          if (u.status === "aborted" || h.status === "aborted")
            return g;
          (u.status === "dirty" || h.status === "dirty") && r.dirty(), c.set(u.value, h.value);
        }
        return { status: r.value, value: c };
      });
    } else {
      const c = /* @__PURE__ */ new Map();
      for (const l of o) {
        const u = l.key, h = l.value;
        if (u.status === "aborted" || h.status === "aborted")
          return g;
        (u.status === "dirty" || h.status === "dirty") && r.dirty(), c.set(u.value, h.value);
      }
      return { status: r.value, value: c };
    }
  }
}
gt.create = (e, t, r) => new gt({
  valueType: t,
  keyType: e,
  typeName: y.ZodMap,
  ..._(r)
});
class oe extends b {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== f.set)
      return p(n, {
        code: d.invalid_type,
        expected: f.set,
        received: n.parsedType
      }), g;
    const a = this._def;
    a.minSize !== null && n.data.size < a.minSize.value && (p(n, {
      code: d.too_small,
      minimum: a.minSize.value,
      type: "set",
      inclusive: !0,
      exact: !1,
      message: a.minSize.message
    }), r.dirty()), a.maxSize !== null && n.data.size > a.maxSize.value && (p(n, {
      code: d.too_big,
      maximum: a.maxSize.value,
      type: "set",
      inclusive: !0,
      exact: !1,
      message: a.maxSize.message
    }), r.dirty());
    const s = this._def.valueType;
    function o(l) {
      const u = /* @__PURE__ */ new Set();
      for (const h of l) {
        if (h.status === "aborted")
          return g;
        h.status === "dirty" && r.dirty(), u.add(h.value);
      }
      return { status: r.value, value: u };
    }
    const c = [...n.data.values()].map((l, u) => s._parse(new H(n, l, n.path, u)));
    return n.common.async ? Promise.all(c).then((l) => o(l)) : o(c);
  }
  min(t, r) {
    return new oe({
      ...this._def,
      minSize: { value: t, message: v.toString(r) }
    });
  }
  max(t, r) {
    return new oe({
      ...this._def,
      maxSize: { value: t, message: v.toString(r) }
    });
  }
  size(t, r) {
    return this.min(t, r).max(t, r);
  }
  nonempty(t) {
    return this.min(1, t);
  }
}
oe.create = (e, t) => new oe({
  valueType: e,
  minSize: null,
  maxSize: null,
  typeName: y.ZodSet,
  ..._(t)
});
class pe extends b {
  constructor() {
    super(...arguments), this.validate = this.implement;
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== f.function)
      return p(r, {
        code: d.invalid_type,
        expected: f.function,
        received: r.parsedType
      }), g;
    function n(c, l) {
      return ht({
        data: c,
        path: r.path,
        errorMaps: [
          r.common.contextualErrorMap,
          r.schemaErrorMap,
          pt(),
          je
        ].filter((u) => !!u),
        issueData: {
          code: d.invalid_arguments,
          argumentsError: l
        }
      });
    }
    function a(c, l) {
      return ht({
        data: c,
        path: r.path,
        errorMaps: [
          r.common.contextualErrorMap,
          r.schemaErrorMap,
          pt(),
          je
        ].filter((u) => !!u),
        issueData: {
          code: d.invalid_return_type,
          returnTypeError: l
        }
      });
    }
    const s = { errorMap: r.common.contextualErrorMap }, o = r.data;
    if (this._def.returns instanceof ve) {
      const c = this;
      return P(async function(...l) {
        const u = new D([]), h = await c._def.args.parseAsync(l, s).catch((C) => {
          throw u.addIssue(n(l, C)), u;
        }), T = await Reflect.apply(o, this, h);
        return await c._def.returns._def.type.parseAsync(T, s).catch((C) => {
          throw u.addIssue(a(T, C)), u;
        });
      });
    } else {
      const c = this;
      return P(function(...l) {
        const u = c._def.args.safeParse(l, s);
        if (!u.success)
          throw new D([n(l, u.error)]);
        const h = Reflect.apply(o, this, u.data), T = c._def.returns.safeParse(h, s);
        if (!T.success)
          throw new D([a(h, T.error)]);
        return T.data;
      });
    }
  }
  parameters() {
    return this._def.args;
  }
  returnType() {
    return this._def.returns;
  }
  args(...t) {
    return new pe({
      ...this._def,
      args: q.create(t).rest(se.create())
    });
  }
  returns(t) {
    return new pe({
      ...this._def,
      returns: t
    });
  }
  implement(t) {
    return this.parse(t);
  }
  strictImplement(t) {
    return this.parse(t);
  }
  static create(t, r, n) {
    return new pe({
      args: t || q.create([]).rest(se.create()),
      returns: r || se.create(),
      typeName: y.ZodFunction,
      ..._(n)
    });
  }
}
class Ve extends b {
  get schema() {
    return this._def.getter();
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    return this._def.getter()._parse({ data: r.data, path: r.path, parent: r });
  }
}
Ve.create = (e, t) => new Ve({
  getter: e,
  typeName: y.ZodLazy,
  ..._(t)
});
class Me extends b {
  _parse(t) {
    if (t.data !== this._def.value) {
      const r = this._getOrReturnCtx(t);
      return p(r, {
        received: r.data,
        code: d.invalid_literal,
        expected: this._def.value
      }), g;
    }
    return { status: "valid", value: t.data };
  }
  get value() {
    return this._def.value;
  }
}
Me.create = (e, t) => new Me({
  value: e,
  typeName: y.ZodLiteral,
  ..._(t)
});
function ga(e, t) {
  return new X({
    values: e,
    typeName: y.ZodEnum,
    ..._(t)
  });
}
class X extends b {
  _parse(t) {
    if (typeof t.data != "string") {
      const r = this._getOrReturnCtx(t), n = this._def.values;
      return p(r, {
        expected: $.joinValues(n),
        received: r.parsedType,
        code: d.invalid_type
      }), g;
    }
    if (this._def.values.indexOf(t.data) === -1) {
      const r = this._getOrReturnCtx(t), n = this._def.values;
      return p(r, {
        received: r.data,
        code: d.invalid_enum_value,
        options: n
      }), g;
    }
    return P(t.data);
  }
  get options() {
    return this._def.values;
  }
  get enum() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  get Values() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  get Enum() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  extract(t) {
    return X.create(t);
  }
  exclude(t) {
    return X.create(this.options.filter((r) => !t.includes(r)));
  }
}
X.create = ga;
class Le extends b {
  _parse(t) {
    const r = $.getValidEnumValues(this._def.values), n = this._getOrReturnCtx(t);
    if (n.parsedType !== f.string && n.parsedType !== f.number) {
      const a = $.objectValues(r);
      return p(n, {
        expected: $.joinValues(a),
        received: n.parsedType,
        code: d.invalid_type
      }), g;
    }
    if (r.indexOf(t.data) === -1) {
      const a = $.objectValues(r);
      return p(n, {
        received: n.data,
        code: d.invalid_enum_value,
        options: a
      }), g;
    }
    return P(t.data);
  }
  get enum() {
    return this._def.values;
  }
}
Le.create = (e, t) => new Le({
  values: e,
  typeName: y.ZodNativeEnum,
  ..._(t)
});
class ve extends b {
  unwrap() {
    return this._def.type;
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== f.promise && r.common.async === !1)
      return p(r, {
        code: d.invalid_type,
        expected: f.promise,
        received: r.parsedType
      }), g;
    const n = r.parsedType === f.promise ? r.data : Promise.resolve(r.data);
    return P(n.then((a) => this._def.type.parseAsync(a, {
      path: r.path,
      errorMap: r.common.contextualErrorMap
    })));
  }
}
ve.create = (e, t) => new ve({
  type: e,
  typeName: y.ZodPromise,
  ..._(t)
});
class U extends b {
  innerType() {
    return this._def.schema;
  }
  sourceType() {
    return this._def.schema._def.typeName === y.ZodEffects ? this._def.schema.sourceType() : this._def.schema;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t), a = this._def.effect || null, s = {
      addIssue: (o) => {
        p(n, o), o.fatal ? r.abort() : r.dirty();
      },
      get path() {
        return n.path;
      }
    };
    if (s.addIssue = s.addIssue.bind(s), a.type === "preprocess") {
      const o = a.transform(n.data, s);
      return n.common.issues.length ? {
        status: "dirty",
        value: n.data
      } : n.common.async ? Promise.resolve(o).then((c) => this._def.schema._parseAsync({
        data: c,
        path: n.path,
        parent: n
      })) : this._def.schema._parseSync({
        data: o,
        path: n.path,
        parent: n
      });
    }
    if (a.type === "refinement") {
      const o = (c) => {
        const l = a.refinement(c, s);
        if (n.common.async)
          return Promise.resolve(l);
        if (l instanceof Promise)
          throw new Error("Async refinement encountered during synchronous parse operation. Use .parseAsync instead.");
        return c;
      };
      if (n.common.async === !1) {
        const c = this._def.schema._parseSync({
          data: n.data,
          path: n.path,
          parent: n
        });
        return c.status === "aborted" ? g : (c.status === "dirty" && r.dirty(), o(c.value), { status: r.value, value: c.value });
      } else
        return this._def.schema._parseAsync({ data: n.data, path: n.path, parent: n }).then((c) => c.status === "aborted" ? g : (c.status === "dirty" && r.dirty(), o(c.value).then(() => ({ status: r.value, value: c.value }))));
    }
    if (a.type === "transform")
      if (n.common.async === !1) {
        const o = this._def.schema._parseSync({
          data: n.data,
          path: n.path,
          parent: n
        });
        if (!Oe(o))
          return o;
        const c = a.transform(o.value, s);
        if (c instanceof Promise)
          throw new Error("Asynchronous transform encountered during synchronous parse operation. Use .parseAsync instead.");
        return { status: r.value, value: c };
      } else
        return this._def.schema._parseAsync({ data: n.data, path: n.path, parent: n }).then((o) => Oe(o) ? Promise.resolve(a.transform(o.value, s)).then((c) => ({ status: r.value, value: c })) : o);
    $.assertNever(a);
  }
}
U.create = (e, t, r) => new U({
  schema: e,
  typeName: y.ZodEffects,
  effect: t,
  ..._(r)
});
U.createWithPreprocess = (e, t, r) => new U({
  schema: t,
  effect: { type: "preprocess", transform: e },
  typeName: y.ZodEffects,
  ..._(r)
});
class z extends b {
  _parse(t) {
    return this._getType(t) === f.undefined ? P(void 0) : this._def.innerType._parse(t);
  }
  unwrap() {
    return this._def.innerType;
  }
}
z.create = (e, t) => new z({
  innerType: e,
  typeName: y.ZodOptional,
  ..._(t)
});
class ce extends b {
  _parse(t) {
    return this._getType(t) === f.null ? P(null) : this._def.innerType._parse(t);
  }
  unwrap() {
    return this._def.innerType;
  }
}
ce.create = (e, t) => new ce({
  innerType: e,
  typeName: y.ZodNullable,
  ..._(t)
});
class De extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    let n = r.data;
    return r.parsedType === f.undefined && (n = this._def.defaultValue()), this._def.innerType._parse({
      data: n,
      path: r.path,
      parent: r
    });
  }
  removeDefault() {
    return this._def.innerType;
  }
}
De.create = (e, t) => new De({
  innerType: e,
  typeName: y.ZodDefault,
  defaultValue: typeof t.default == "function" ? t.default : () => t.default,
  ..._(t)
});
class _t extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = {
      ...r,
      common: {
        ...r.common,
        issues: []
      }
    }, a = this._def.innerType._parse({
      data: n.data,
      path: n.path,
      parent: {
        ...n
      }
    });
    return vt(a) ? a.then((s) => ({
      status: "valid",
      value: s.status === "valid" ? s.value : this._def.catchValue({
        get error() {
          return new D(n.common.issues);
        },
        input: n.data
      })
    })) : {
      status: "valid",
      value: a.status === "valid" ? a.value : this._def.catchValue({
        get error() {
          return new D(n.common.issues);
        },
        input: n.data
      })
    };
  }
  removeCatch() {
    return this._def.innerType;
  }
}
_t.create = (e, t) => new _t({
  innerType: e,
  typeName: y.ZodCatch,
  catchValue: typeof t.catch == "function" ? t.catch : () => t.catch,
  ..._(t)
});
class bt extends b {
  _parse(t) {
    if (this._getType(t) !== f.nan) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.nan,
        received: n.parsedType
      }), g;
    }
    return { status: "valid", value: t.data };
  }
}
bt.create = (e) => new bt({
  typeName: y.ZodNaN,
  ..._(e)
});
const Lh = Symbol("zod_brand");
class _a extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = r.data;
    return this._def.type._parse({
      data: n,
      path: r.path,
      parent: r
    });
  }
  unwrap() {
    return this._def.type;
  }
}
class He extends b {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.common.async)
      return (async () => {
        const s = await this._def.in._parseAsync({
          data: n.data,
          path: n.path,
          parent: n
        });
        return s.status === "aborted" ? g : s.status === "dirty" ? (r.dirty(), ma(s.value)) : this._def.out._parseAsync({
          data: s.value,
          path: n.path,
          parent: n
        });
      })();
    {
      const a = this._def.in._parseSync({
        data: n.data,
        path: n.path,
        parent: n
      });
      return a.status === "aborted" ? g : a.status === "dirty" ? (r.dirty(), {
        status: "dirty",
        value: a.value
      }) : this._def.out._parseSync({
        data: a.value,
        path: n.path,
        parent: n
      });
    }
  }
  static create(t, r) {
    return new He({
      in: t,
      out: r,
      typeName: y.ZodPipeline
    });
  }
}
class Tt extends b {
  _parse(t) {
    const r = this._def.innerType._parse(t);
    return Oe(r) && (r.value = Object.freeze(r.value)), r;
  }
}
Tt.create = (e, t) => new Tt({
  innerType: e,
  typeName: y.ZodReadonly,
  ..._(t)
});
const ba = (e, t = {}, r) => e ? he.create().superRefine((n, a) => {
  var s, o;
  if (!e(n)) {
    const c = typeof t == "function" ? t(n) : typeof t == "string" ? { message: t } : t, l = (o = (s = c.fatal) !== null && s !== void 0 ? s : r) !== null && o !== void 0 ? o : !0, u = typeof c == "string" ? { message: c } : c;
    a.addIssue({ code: "custom", ...u, fatal: l });
  }
}) : he.create(), Dh = {
  object: w.lazycreate
};
var y;
(function(e) {
  e.ZodString = "ZodString", e.ZodNumber = "ZodNumber", e.ZodNaN = "ZodNaN", e.ZodBigInt = "ZodBigInt", e.ZodBoolean = "ZodBoolean", e.ZodDate = "ZodDate", e.ZodSymbol = "ZodSymbol", e.ZodUndefined = "ZodUndefined", e.ZodNull = "ZodNull", e.ZodAny = "ZodAny", e.ZodUnknown = "ZodUnknown", e.ZodNever = "ZodNever", e.ZodVoid = "ZodVoid", e.ZodArray = "ZodArray", e.ZodObject = "ZodObject", e.ZodUnion = "ZodUnion", e.ZodDiscriminatedUnion = "ZodDiscriminatedUnion", e.ZodIntersection = "ZodIntersection", e.ZodTuple = "ZodTuple", e.ZodRecord = "ZodRecord", e.ZodMap = "ZodMap", e.ZodSet = "ZodSet", e.ZodFunction = "ZodFunction", e.ZodLazy = "ZodLazy", e.ZodLiteral = "ZodLiteral", e.ZodEnum = "ZodEnum", e.ZodEffects = "ZodEffects", e.ZodNativeEnum = "ZodNativeEnum", e.ZodOptional = "ZodOptional", e.ZodNullable = "ZodNullable", e.ZodDefault = "ZodDefault", e.ZodCatch = "ZodCatch", e.ZodPromise = "ZodPromise", e.ZodBranded = "ZodBranded", e.ZodPipeline = "ZodPipeline", e.ZodReadonly = "ZodReadonly";
})(y || (y = {}));
const Zh = (e, t = {
  message: `Input not instance of ${e.name}`
}) => ba((r) => r instanceof e, t), Ta = L.create, $a = Y.create, Uh = bt.create, Hh = J.create, Aa = ke.create, qh = ie.create, Bh = yt.create, Gh = Re.create, zh = Ce.create, Kh = he.create, Fh = se.create, Qh = K.create, Wh = mt.create, Yh = Z.create, Jh = w.create, Xh = w.strictCreate, ev = Ie.create, tv = Vt.create, rv = Ne.create, nv = q.create, av = Pe.create, sv = gt.create, iv = oe.create, ov = pe.create, cv = Ve.create, uv = Me.create, lv = X.create, dv = Le.create, fv = ve.create, _n = U.create, pv = z.create, hv = ce.create, vv = U.createWithPreprocess, yv = He.create, mv = () => Ta().optional(), gv = () => $a().optional(), _v = () => Aa().optional(), bv = {
  string: (e) => L.create({ ...e, coerce: !0 }),
  number: (e) => Y.create({ ...e, coerce: !0 }),
  boolean: (e) => ke.create({
    ...e,
    coerce: !0
  }),
  bigint: (e) => J.create({ ...e, coerce: !0 }),
  date: (e) => ie.create({ ...e, coerce: !0 })
}, Tv = g;
var i = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  defaultErrorMap: je,
  setErrorMap: Eh,
  getErrorMap: pt,
  makeIssue: ht,
  EMPTY_PATH: wh,
  addIssueToContext: p,
  ParseStatus: I,
  INVALID: g,
  DIRTY: ma,
  OK: P,
  isAborted: nr,
  isDirty: ar,
  isValid: Oe,
  isAsync: vt,
  get util() {
    return $;
  },
  get objectUtil() {
    return rr;
  },
  ZodParsedType: f,
  getParsedType: Q,
  ZodType: b,
  ZodString: L,
  ZodNumber: Y,
  ZodBigInt: J,
  ZodBoolean: ke,
  ZodDate: ie,
  ZodSymbol: yt,
  ZodUndefined: Re,
  ZodNull: Ce,
  ZodAny: he,
  ZodUnknown: se,
  ZodNever: K,
  ZodVoid: mt,
  ZodArray: Z,
  ZodObject: w,
  ZodUnion: Ie,
  ZodDiscriminatedUnion: Vt,
  ZodIntersection: Ne,
  ZodTuple: q,
  ZodRecord: Pe,
  ZodMap: gt,
  ZodSet: oe,
  ZodFunction: pe,
  ZodLazy: Ve,
  ZodLiteral: Me,
  ZodEnum: X,
  ZodNativeEnum: Le,
  ZodPromise: ve,
  ZodEffects: U,
  ZodTransformer: U,
  ZodOptional: z,
  ZodNullable: ce,
  ZodDefault: De,
  ZodCatch: _t,
  ZodNaN: bt,
  BRAND: Lh,
  ZodBranded: _a,
  ZodPipeline: He,
  ZodReadonly: Tt,
  custom: ba,
  Schema: b,
  ZodSchema: b,
  late: Dh,
  get ZodFirstPartyTypeKind() {
    return y;
  },
  coerce: bv,
  any: Kh,
  array: Yh,
  bigint: Hh,
  boolean: Aa,
  date: qh,
  discriminatedUnion: tv,
  effect: _n,
  enum: lv,
  function: ov,
  instanceof: Zh,
  intersection: rv,
  lazy: cv,
  literal: uv,
  map: sv,
  nan: Uh,
  nativeEnum: dv,
  never: Qh,
  null: zh,
  nullable: hv,
  number: $a,
  object: Jh,
  oboolean: _v,
  onumber: gv,
  optional: pv,
  ostring: mv,
  pipeline: yv,
  preprocess: vv,
  promise: fv,
  record: av,
  set: iv,
  strictObject: Xh,
  string: Ta,
  symbol: Bh,
  transformer: _n,
  tuple: nv,
  undefined: Gh,
  union: ev,
  unknown: Fh,
  void: Wh,
  NEVER: Tv,
  ZodIssueCode: d,
  quotelessJson: Sh,
  ZodError: D
}), j = (e) => e, $v = class {
  /**
   * @package
   */
  constructor(e, t, r) {
    this.versionMap = e, this.latestVersion = t, this.getVersion = r;
  }
  /**
   * Returns whether the given data is a valid entity of any version of the entity.
   * @param data The data to check
   * @returns Whether the given data is a valid entity of any version of the entity.
   */
  is(e) {
    let t = this.getVersion(e);
    if (t === null)
      return !1;
    const r = this.versionMap[t];
    return r ? r.schema.safeParse(e).success : !1;
  }
  /**
   * Returns whether the given data is a valid entity of the latest version of the entity.
   * @param data The data to check
   * @returns Whether the given data is a valid entity of the latest version of the entity.
   */
  isLatest(e) {
    return this.versionMap[this.latestVersion].schema.safeParse(e).success;
  }
  /**
   * Similar to Zod's `safeParse` method, but also migrates the data to the latest version.
   * @param data The data to parse
   * @returns The result from parsing data, if successful, older versions are migrated to the latest version
   */
  safeParse(e) {
    const t = this.getVersion(e);
    if (t === null)
      return { type: "err", error: { type: "VER_CHECK_FAIL" } };
    const r = this.versionMap[t];
    if (!r)
      return { type: "err", error: { type: "INVALID_VER" } };
    const n = r.schema.safeParse(e);
    if (!n.success)
      return {
        type: "err",
        error: {
          type: "GIVEN_VER_VALIDATION_FAIL",
          version: t,
          versionDef: r,
          error: n.error
        }
      };
    let a = e;
    for (let s = t + 1; s <= this.latestVersion; s++) {
      const o = this.versionMap[s];
      if (!o)
        return {
          type: "err",
          error: { type: "BUG_NO_INTERMEDIATE_FOUND", missingVer: s }
        };
      if (o.initial)
        return {
          type: "err",
          error: { type: "BUG_INTERMEDIATE_MARKED_INITIAL", ver: s }
        };
      a = o.up(a);
    }
    return { type: "ok", value: a };
  }
};
function Mt(e) {
  return new $v(e.versionMap, e.latestVersion, e.getVersion);
}
function $t(e) {
  return i.custom((t) => e.is(t)).transform((t) => {
    const r = e.safeParse(t);
    if (r.type !== "ok")
      throw new Error(
        "Invalid entity definition. `entity.is` returned success, safeParse failed."
      );
    return r.value;
  });
}
var Av = "__lodash_hash_undefined__";
function Sv(e) {
  return this.__data__.set(e, Av), this;
}
var Ev = Sv;
function wv(e) {
  return this.__data__.has(e);
}
var xv = wv, jv = Qn, Ov = Ev, kv = xv;
function At(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.__data__ = new jv(); ++t < r; )
    this.add(e[t]);
}
At.prototype.add = At.prototype.push = Ov;
At.prototype.has = kv;
var Rv = At;
function Cv(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length; ++r < n; )
    if (t(e[r], r, e))
      return !0;
  return !1;
}
var Iv = Cv;
function Nv(e, t) {
  return e.has(t);
}
var Pv = Nv, Vv = Rv, Mv = Iv, Lv = Pv, Dv = 1, Zv = 2;
function Uv(e, t, r, n, a, s) {
  var o = r & Dv, c = e.length, l = t.length;
  if (c != l && !(o && l > c))
    return !1;
  var u = s.get(e), h = s.get(t);
  if (u && h)
    return u == t && h == e;
  var T = -1, A = !0, C = r & Zv ? new Vv() : void 0;
  for (s.set(e, t), s.set(t, e); ++T < c; ) {
    var V = e[T], M = t[T];
    if (n)
      var R = o ? n(M, V, T, t, e, s) : n(V, M, T, e, t, s);
    if (R !== void 0) {
      if (R)
        continue;
      A = !1;
      break;
    }
    if (C) {
      if (!Mv(t, function(N, ne) {
        if (!Lv(C, ne) && (V === N || a(V, N, r, n, s)))
          return C.push(ne);
      })) {
        A = !1;
        break;
      }
    } else if (!(V === M || a(V, M, r, n, s))) {
      A = !1;
      break;
    }
  }
  return s.delete(e), s.delete(t), A;
}
var Sa = Uv;
function Hv(e) {
  var t = -1, r = Array(e.size);
  return e.forEach(function(n, a) {
    r[++t] = [a, n];
  }), r;
}
var qv = Hv;
function Bv(e) {
  var t = -1, r = Array(e.size);
  return e.forEach(function(n) {
    r[++t] = n;
  }), r;
}
var Gv = Bv, bn = Ot, Tn = fa, zv = dr, Kv = Sa, Fv = qv, Qv = Gv, Wv = 1, Yv = 2, Jv = "[object Boolean]", Xv = "[object Date]", ey = "[object Error]", ty = "[object Map]", ry = "[object Number]", ny = "[object RegExp]", ay = "[object Set]", sy = "[object String]", iy = "[object Symbol]", oy = "[object ArrayBuffer]", cy = "[object DataView]", $n = bn ? bn.prototype : void 0, Ft = $n ? $n.valueOf : void 0;
function uy(e, t, r, n, a, s, o) {
  switch (r) {
    case cy:
      if (e.byteLength != t.byteLength || e.byteOffset != t.byteOffset)
        return !1;
      e = e.buffer, t = t.buffer;
    case oy:
      return !(e.byteLength != t.byteLength || !s(new Tn(e), new Tn(t)));
    case Jv:
    case Xv:
    case ry:
      return zv(+e, +t);
    case ey:
      return e.name == t.name && e.message == t.message;
    case ny:
    case sy:
      return e == t + "";
    case ty:
      var c = Fv;
    case ay:
      var l = n & Wv;
      if (c || (c = Qv), e.size != t.size && !l)
        return !1;
      var u = o.get(e);
      if (u)
        return u == t;
      n |= Yv, o.set(e, t);
      var h = Kv(c(e), c(t), n, a, s, o);
      return o.delete(e), h;
    case iy:
      if (Ft)
        return Ft.call(e) == Ft.call(t);
  }
  return !1;
}
var ly = uy, An = la, dy = 1, fy = Object.prototype, py = fy.hasOwnProperty;
function hy(e, t, r, n, a, s) {
  var o = r & dy, c = An(e), l = c.length, u = An(t), h = u.length;
  if (l != h && !o)
    return !1;
  for (var T = l; T--; ) {
    var A = c[T];
    if (!(o ? A in t : py.call(t, A)))
      return !1;
  }
  var C = s.get(e), V = s.get(t);
  if (C && V)
    return C == t && V == e;
  var M = !0;
  s.set(e, t), s.set(t, e);
  for (var R = o; ++T < l; ) {
    A = c[T];
    var N = e[A], ne = t[A];
    if (n)
      var Br = o ? n(ne, N, A, t, e, s) : n(N, ne, A, e, t, s);
    if (!(Br === void 0 ? N === ne || a(N, ne, r, n, s) : Br)) {
      M = !1;
      break;
    }
    R || (R = A == "constructor");
  }
  if (M && !R) {
    var at = e.constructor, st = t.constructor;
    at != st && "constructor" in e && "constructor" in t && !(typeof at == "function" && at instanceof at && typeof st == "function" && st instanceof st) && (M = !1);
  }
  return s.delete(e), s.delete(t), M;
}
var vy = hy, Qt = Wn, yy = Sa, my = ly, gy = vy, Sn = Pt, En = Nt, wn = pr, _y = ta, by = 1, xn = "[object Arguments]", jn = "[object Array]", ot = "[object Object]", Ty = Object.prototype, On = Ty.hasOwnProperty;
function $y(e, t, r, n, a, s) {
  var o = En(e), c = En(t), l = o ? jn : Sn(e), u = c ? jn : Sn(t);
  l = l == xn ? ot : l, u = u == xn ? ot : u;
  var h = l == ot, T = u == ot, A = l == u;
  if (A && wn(e)) {
    if (!wn(t))
      return !1;
    o = !0, h = !1;
  }
  if (A && !h)
    return s || (s = new Qt()), o || _y(e) ? yy(e, t, r, n, a, s) : my(e, t, l, r, n, a, s);
  if (!(r & by)) {
    var C = h && On.call(e, "__wrapped__"), V = T && On.call(t, "__wrapped__");
    if (C || V) {
      var M = C ? e.value() : e, R = V ? t.value() : t;
      return s || (s = new Qt()), a(M, R, r, n, s);
    }
  }
  return A ? (s || (s = new Qt()), gy(e, t, r, n, a, s)) : !1;
}
var Ay = $y, Sy = Ay, kn = Se;
function Ea(e, t, r, n, a) {
  return e === t ? !0 : e == null || t == null || !kn(e) && !kn(t) ? e !== e && t !== t : Sy(e, t, r, n, Ea, a);
}
var Ey = Ea, wy = Ey;
function xy(e, t) {
  return wy(e, t);
}
var jy = xy;
const Oy = /* @__PURE__ */ Bn(jy), ky = (e) => ({
  equals(t, r) {
    return t !== void 0 && r !== void 0 ? e.equals(t, r) : t === void 0 && r === void 0;
  }
}), Wt = (e, t) => ({
  equals(r, n) {
    return t.equals(e(r), e(n));
  }
}), xe = {
  equals(e, t) {
    return Oy(e, t);
  }
}, Ry = i.object({
  id: i.optional(i.string()),
  // Firebase Firestore ID
  url: i.string(),
  path: i.string(),
  headers: i.array(
    i.object({
      key: i.string(),
      value: i.string(),
      active: i.boolean()
    })
  ),
  params: i.array(
    i.object({
      key: i.string(),
      value: i.string(),
      active: i.boolean()
    })
  ),
  name: i.string(),
  method: i.string(),
  preRequestScript: i.string(),
  testScript: i.string(),
  contentType: i.string(),
  body: i.string(),
  rawParams: i.optional(i.string()),
  auth: i.optional(i.string()),
  httpUser: i.optional(i.string()),
  httpPassword: i.optional(i.string()),
  bearerToken: i.optional(i.string())
}), Rn = j({
  initial: !0,
  schema: Ry
}), Tr = i.object({
  key: i.string(),
  active: i.boolean()
}).and(
  i.union([
    i.object({
      isFile: i.literal(!0),
      value: i.array(i.instanceof(Blob).nullable())
    }),
    i.object({
      isFile: i.literal(!1),
      value: i.string()
    })
  ])
), Zg = i.object({
  contentType: i.literal("multipart/form-data"),
  body: i.array(Tr)
}), Cy = i.union([
  i.object({
    contentType: i.literal(null),
    body: i.literal(null).catch(null)
  }),
  i.object({
    contentType: i.literal("multipart/form-data"),
    body: i.array(Tr).catch([])
  }),
  i.object({
    contentType: i.union([
      i.literal("application/json"),
      i.literal("application/ld+json"),
      i.literal("application/hal+json"),
      i.literal("application/vnd.api+json"),
      i.literal("application/xml"),
      i.literal("application/x-www-form-urlencoded"),
      i.literal("text/html"),
      i.literal("text/plain")
    ]),
    body: i.string().catch("")
  })
]), qe = i.object({
  authType: i.literal("none")
}), Be = i.object({
  authType: i.literal("basic"),
  username: i.string().catch(""),
  password: i.string().catch("")
}), Ge = i.object({
  authType: i.literal("bearer"),
  token: i.string().catch("")
}), Iy = i.object({
  authType: i.literal("oauth-2"),
  token: i.string().catch(""),
  oidcDiscoveryURL: i.string().catch(""),
  authURL: i.string().catch(""),
  accessTokenURL: i.string().catch(""),
  clientID: i.string().catch(""),
  scope: i.string().catch("")
}), Lt = i.object({
  authType: i.literal("api-key"),
  key: i.string().catch(""),
  value: i.string().catch(""),
  addTo: i.string().catch("Headers")
}), ze = i.object({
  authType: i.literal("inherit")
}), Ny = i.discriminatedUnion("authType", [
  qe,
  ze,
  Be,
  Ge,
  Iy,
  Lt
]).and(
  i.object({
    authActive: i.boolean()
  })
), Py = i.array(
  i.object({
    key: i.string().catch(""),
    value: i.string().catch(""),
    active: i.boolean().catch(!0)
  })
), wa = i.array(
  i.object({
    key: i.string().catch(""),
    value: i.string().catch(""),
    active: i.boolean().catch(!0)
  })
), xa = i.object({
  v: i.literal("1"),
  id: i.optional(i.string()),
  // Firebase Firestore ID
  name: i.string(),
  method: i.string(),
  endpoint: i.string(),
  params: Py,
  headers: wa,
  preRequestScript: i.string().catch(""),
  testScript: i.string().catch(""),
  auth: Ny,
  body: Cy
});
function Vy(e) {
  return {
    contentType: "application/json",
    body: e.contentType === "application/json" ? e.rawParams ?? "" : ""
  };
}
function My(e) {
  return !e.auth || e.auth === "None" ? {
    authType: "none",
    authActive: !0
  } : e.auth === "Basic Auth" ? {
    authType: "basic",
    authActive: !0,
    username: e.httpUser ?? "",
    password: e.httpPassword ?? ""
  } : e.auth === "Bearer Token" ? {
    authType: "bearer",
    authActive: !0,
    token: e.bearerToken ?? ""
  } : { authType: "none", authActive: !0 };
}
const Ly = j({
  initial: !1,
  schema: xa,
  up(e) {
    const {
      url: t,
      path: r,
      headers: n,
      params: a,
      name: s,
      method: o,
      preRequestScript: c,
      testScript: l
    } = e, u = `${t}${r}`, h = Vy(e), T = My(e), A = {
      v: "1",
      endpoint: u,
      headers: n,
      params: a,
      name: s,
      method: o,
      preRequestScript: c,
      testScript: l,
      body: h,
      auth: T
    };
    return e.id && (A.id = e.id), A;
  }
}), ja = i.array(
  i.object({
    key: i.string().catch(""),
    value: i.string().catch(""),
    active: i.boolean().catch(!0)
  })
), Oa = xa.extend({
  v: i.literal("2"),
  requestVariables: ja
}), Dy = j({
  initial: !1,
  schema: Oa,
  up(e) {
    return {
      ...e,
      v: "2",
      requestVariables: []
    };
  }
}), ka = i.object({
  grantType: i.literal("AUTHORIZATION_CODE"),
  authEndpoint: i.string().trim(),
  tokenEndpoint: i.string().trim(),
  clientID: i.string().trim(),
  clientSecret: i.string().trim(),
  scopes: i.string().trim().optional(),
  token: i.string().catch(""),
  isPKCE: i.boolean(),
  codeVerifierMethod: i.union([i.literal("plain"), i.literal("S256")]).optional()
}), $r = i.object({
  grantType: i.literal("CLIENT_CREDENTIALS"),
  authEndpoint: i.string().trim(),
  clientID: i.string().trim(),
  clientSecret: i.string().trim(),
  scopes: i.string().trim().optional(),
  token: i.string().catch("")
}), Ar = i.object({
  grantType: i.literal("PASSWORD"),
  authEndpoint: i.string().trim(),
  clientID: i.string().trim(),
  clientSecret: i.string().trim(),
  scopes: i.string().trim().optional(),
  username: i.string().trim(),
  password: i.string().trim(),
  token: i.string().catch("")
}), Sr = i.object({
  grantType: i.literal("IMPLICIT"),
  authEndpoint: i.string().trim(),
  clientID: i.string().trim(),
  scopes: i.string().trim().optional(),
  token: i.string().catch("")
}), Er = i.object({
  authType: i.literal("oauth-2"),
  grantTypeInfo: i.discriminatedUnion("grantType", [
    ka,
    $r,
    Ar,
    Sr
  ]),
  addTo: i.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), Zy = i.discriminatedUnion("authType", [
  qe,
  ze,
  Be,
  Ge,
  Er,
  Lt
]).and(
  i.object({
    authActive: i.boolean()
  })
), Ra = Oa.extend({
  v: i.literal("3"),
  auth: Zy
}), Uy = j({
  initial: !1,
  schema: Ra,
  up(e) {
    if (e.auth.authType === "oauth-2") {
      const { token: t, accessTokenURL: r, scope: n, clientID: a, authURL: s } = e.auth;
      return {
        ...e,
        v: "3",
        auth: {
          ...e.auth,
          authType: "oauth-2",
          grantTypeInfo: {
            grantType: "AUTHORIZATION_CODE",
            authEndpoint: s,
            tokenEndpoint: r,
            clientID: a,
            clientSecret: "",
            scopes: n,
            isPKCE: !1,
            token: t
          },
          addTo: "HEADERS"
        }
      };
    }
    return {
      ...e,
      v: "3",
      auth: {
        ...e.auth
      }
    };
  }
}), Ca = Lt.extend({
  addTo: i.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), Hy = i.discriminatedUnion("authType", [
  qe,
  ze,
  Be,
  Ge,
  Er,
  Ca
]).and(
  i.object({
    authActive: i.boolean()
  })
), Ia = Ra.extend({
  v: i.literal("4"),
  auth: Hy
}), qy = j({
  schema: Ia,
  initial: !1,
  up(e) {
    return e.auth.authType === "api-key" ? {
      ...e,
      v: "4",
      auth: {
        ...e.auth,
        addTo: e.auth.addTo === "Query params" ? "QUERY_PARAMS" : "HEADERS"
      }
    } : {
      ...e,
      auth: {
        ...e.auth
      },
      v: "4"
    };
  }
}), Na = ka.extend({
  clientSecret: i.string().optional()
}), wr = Er.extend({
  grantTypeInfo: i.discriminatedUnion("grantType", [
    Na,
    $r,
    Ar,
    Sr
  ])
}), Pa = i.discriminatedUnion("authType", [
  qe,
  ze,
  Be,
  Ge,
  wr,
  Ca
]).and(
  i.object({
    authActive: i.boolean()
  })
), Va = Ia.extend({
  v: i.literal("5"),
  auth: Pa
}), By = j({
  schema: Va,
  initial: !1,
  up(e) {
    return {
      ...e,
      v: "5"
    };
  }
}), Ma = i.union([
  i.object({
    contentType: i.literal(null),
    body: i.literal(null).catch(null)
  }),
  i.object({
    contentType: i.literal("multipart/form-data"),
    body: i.array(Tr).catch([])
  }),
  i.object({
    contentType: i.union([
      i.literal("application/json"),
      i.literal("application/ld+json"),
      i.literal("application/hal+json"),
      i.literal("application/vnd.api+json"),
      i.literal("application/xml"),
      i.literal("text/xml"),
      i.literal("application/x-www-form-urlencoded"),
      i.literal("text/html"),
      i.literal("text/plain")
    ]),
    body: i.string().catch("")
  })
]), La = Va.extend({
  v: i.literal("6"),
  body: Ma
}), Gy = j({
  schema: La,
  initial: !1,
  up(e) {
    return {
      ...e,
      v: "6"
    };
  }
}), zy = Na.extend({
  refreshToken: i.string().optional()
}), xr = i.object({
  authType: i.literal("oauth-2"),
  grantTypeInfo: i.discriminatedUnion("grantType", [
    zy,
    $r,
    Ar,
    Sr
  ]),
  addTo: i.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), Da = i.array(
  i.object({
    key: i.string().catch(""),
    value: i.string().catch(""),
    active: i.boolean().catch(!0),
    description: i.string().catch("")
  })
), jr = i.array(
  i.object({
    key: i.string().catch(""),
    value: i.string().catch(""),
    active: i.boolean().catch(!0),
    description: i.string().catch("")
  })
), Za = i.object({
  authType: i.literal("aws-signature"),
  accessKey: i.string().catch(""),
  secretKey: i.string().catch(""),
  region: i.string().catch(""),
  serviceName: i.string().catch(""),
  serviceToken: i.string().optional(),
  signature: i.object({}).optional(),
  addTo: i.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), Or = i.discriminatedUnion("authType", [
  qe,
  ze,
  Be,
  Ge,
  xr,
  Lt,
  Za
]).and(
  i.object({
    authActive: i.boolean()
  })
), Ky = La.extend({
  v: i.literal("7"),
  params: Da,
  headers: jr,
  auth: Or
}), Fy = j({
  schema: Ky,
  initial: !1,
  up(e) {
    const t = e.params.map((n) => ({
      ...n,
      description: ""
    })), r = e.headers.map((n) => ({
      ...n,
      description: ""
    }));
    return {
      ...e,
      v: "7",
      params: t,
      headers: r
      // no need to update anything for HoppRESTAuth, because the newly added refreshToken is optional
    };
  }
}), Qy = {
  "application/json": "json",
  "application/ld+json": "json",
  "application/hal+json": "json",
  "application/vnd.api+json": "json",
  "application/xml": "xml",
  "text/xml": "xml",
  "application/x-www-form-urlencoded": "multipart",
  "multipart/form-data": "multipart",
  "text/html": "html",
  "text/plain": "plain"
}, Ug = Object.keys(
  Qy
), Wy = i.object({
  // v is a stringified number
  v: i.string().regex(/^\d+$/).transform(Number)
}), Dt = Mt({
  latestVersion: 7,
  versionMap: {
    0: Rn,
    1: Ly,
    2: Dy,
    3: Uy,
    4: qy,
    5: By,
    6: Gy,
    7: Fy
  },
  getVersion(e) {
    const t = Wy.safeParse(e);
    return t.success ? t.data.v : Rn.schema.safeParse(e).success ? 0 : null;
  }
}), Yy = Os({
  id: ky(F),
  v: F,
  auth: xe,
  body: xe,
  endpoint: F,
  headers: Wt(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    xe
  ),
  params: Wt(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    xe
  ),
  method: F,
  name: F,
  preRequestScript: F,
  testScript: F,
  requestVariables: Wt(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    xe
  )
}), Ua = "7", Hg = Yy.equals;
function qg(e, t) {
  const r = Ah(t);
  if (e && typeof e == "object") {
    if ("id" in e && typeof e.id == "string" && (r.id = e.id), "name" in e && typeof e.name == "string" && (r.name = e.name), "method" in e && typeof e.method == "string" && (r.method = e.method), "endpoint" in e && typeof e.endpoint == "string" && (r.endpoint = e.endpoint), "preRequestScript" in e && typeof e.preRequestScript == "string" && (r.preRequestScript = e.preRequestScript), "testScript" in e && typeof e.testScript == "string" && (r.testScript = e.testScript), "body" in e) {
      const n = Ma.safeParse(e.body);
      n.success && (r.body = n.data);
    }
    if ("auth" in e) {
      const n = Or.safeParse(e.auth);
      n.success && (r.auth = n.data);
    }
    if ("params" in e) {
      const n = Da.safeParse(e.params);
      n.success && (r.params = n.data);
    }
    if ("headers" in e) {
      const n = jr.safeParse(e.headers);
      n.success && (r.headers = n.data);
    }
    if ("requestVariables" in e) {
      const n = ja.safeParse(e.requestVariables);
      n.success && (r.requestVariables = n.data);
    }
  }
  return r;
}
function Bg(e) {
  return {
    v: Ua,
    ...e
  };
}
function Jy() {
  return {
    v: Ua,
    endpoint: "https://echo.hoppscotch.io",
    name: "Untitled",
    params: [],
    headers: [],
    method: "GET",
    auth: {
      authType: "inherit",
      authActive: !0
    },
    preRequestScript: "",
    testScript: "",
    body: {
      contentType: null,
      body: null
    },
    requestVariables: []
  };
}
function Gg(e) {
  return Dt.isLatest(e);
}
function Xy(e) {
  const t = Dt.safeParse(e);
  return t.type === "ok" ? t.value : Jy();
}
const kr = i.object({
  key: i.string().catch(""),
  value: i.string().catch(""),
  active: i.boolean().catch(!0)
}), em = i.object({
  v: i.literal(1),
  name: i.string(),
  url: i.string(),
  headers: i.array(kr).catch([]),
  query: i.string(),
  variables: i.string()
}), tm = j({
  initial: !0,
  schema: em
}), Ke = i.object({
  authType: i.literal("none")
}), Fe = i.object({
  authType: i.literal("basic"),
  username: i.string().catch(""),
  password: i.string().catch("")
}), Qe = i.object({
  authType: i.literal("bearer"),
  token: i.string().catch("")
}), rm = i.object({
  authType: i.literal("oauth-2"),
  token: i.string().catch(""),
  oidcDiscoveryURL: i.string().catch(""),
  authURL: i.string().catch(""),
  accessTokenURL: i.string().catch(""),
  clientID: i.string().catch(""),
  scope: i.string().catch("")
}), Zt = i.object({
  authType: i.literal("api-key"),
  key: i.string().catch(""),
  value: i.string().catch(""),
  addTo: i.string().catch("Headers")
}), We = i.object({
  authType: i.literal("inherit")
}), nm = i.discriminatedUnion("authType", [
  Ke,
  Fe,
  Qe,
  rm,
  Zt,
  We
]).and(
  i.object({
    authActive: i.boolean()
  })
), Ha = i.object({
  id: i.optional(i.string()),
  v: i.literal(2),
  name: i.string(),
  url: i.string(),
  headers: i.array(kr).catch([]),
  query: i.string(),
  variables: i.string(),
  auth: nm
}), am = j({
  initial: !1,
  schema: Ha,
  up(e) {
    return {
      ...e,
      v: 2,
      auth: {
        authActive: !0,
        authType: "none"
      }
    };
  }
}), sm = i.discriminatedUnion("authType", [
  Ke,
  We,
  Fe,
  Qe,
  Zt,
  xr
  // both rest and gql have the same auth type for oauth2
]).and(
  i.object({
    authActive: i.boolean()
  })
), qa = Ha.extend({
  v: i.literal(3),
  auth: sm
}), im = j({
  initial: !1,
  schema: qa,
  up(e) {
    if (e.auth.authType === "oauth-2") {
      const { token: t, accessTokenURL: r, scope: n, clientID: a, authURL: s } = e.auth;
      return {
        ...e,
        v: 3,
        auth: {
          ...e.auth,
          authType: "oauth-2",
          grantTypeInfo: {
            grantType: "AUTHORIZATION_CODE",
            authEndpoint: s,
            tokenEndpoint: r,
            clientID: a,
            clientSecret: "",
            scopes: n,
            isPKCE: !1,
            token: t
          },
          addTo: "HEADERS"
        }
      };
    }
    return {
      ...e,
      v: 3,
      auth: {
        ...e.auth
      }
    };
  }
}), Ba = Zt.extend({
  addTo: i.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), om = i.discriminatedUnion("authType", [
  Ke,
  We,
  Fe,
  Qe,
  Ba,
  xr
  // both rest and gql have the same auth type for oauth2
]).and(
  i.object({
    authActive: i.boolean()
  })
), Ga = qa.extend({
  v: i.literal(4),
  auth: om
}), cm = j({
  initial: !1,
  schema: Ga,
  up(e) {
    return e.auth.authType === "api-key" ? {
      ...e,
      v: 4,
      auth: {
        ...e.auth,
        addTo: e.auth.addTo === "Query params" ? "QUERY_PARAMS" : "HEADERS"
      }
    } : {
      ...e,
      v: 4,
      auth: {
        ...e.auth
      }
    };
  }
}), za = i.discriminatedUnion("authType", [
  Ke,
  We,
  Fe,
  Qe,
  Ba,
  wr
  // both rest and gql have the same auth type for oauth2
]).and(
  i.object({
    authActive: i.boolean()
  })
), Ka = Ga.extend({
  v: i.literal(5),
  auth: za
}), um = j({
  initial: !1,
  schema: Ka,
  up(e) {
    return {
      ...e,
      v: 5
    };
  }
}), lm = Za, Fa = i.object({
  key: i.string().catch(""),
  value: i.string().catch(""),
  active: i.boolean().catch(!0),
  description: i.string().catch("")
}), Qa = i.discriminatedUnion("authType", [
  Ke,
  We,
  Fe,
  Qe,
  wr,
  Zt,
  lm
]).and(
  i.object({
    authActive: i.boolean()
  })
), dm = Ka.extend({
  v: i.literal(6),
  auth: Qa,
  headers: i.array(Fa).catch([])
}), fm = j({
  schema: dm,
  initial: !1,
  up(e) {
    const t = e.headers.map((r) => ({
      ...r,
      description: ""
    }));
    return {
      ...e,
      v: 6,
      headers: t
    };
  }
}), Wa = 6, pm = i.object({
  v: i.number()
}), Rr = Mt({
  latestVersion: 6,
  versionMap: {
    1: tm,
    2: am,
    3: im,
    4: cm,
    5: um,
    6: fm
  },
  getVersion(e) {
    const t = pm.safeParse(e);
    return t.success ? t.data.v : null;
  }
}), hm = `
query Request {
  method
  url
  headers {
    key
    value
  }
}`.trim();
function vm() {
  return {
    v: Wa,
    name: "Untitled",
    url: "https://echo.hoppscotch.io/graphql",
    headers: [],
    variables: `
{
  "id": "1"
}`.trim(),
    query: hm,
    auth: {
      authType: "inherit",
      authActive: !0
    }
  };
}
function ym(e) {
  const t = Rr.safeParse(e);
  return t.type === "ok" ? t.value : vm();
}
function zg(e) {
  return {
    v: Wa,
    ...e
  };
}
const mm = i.object({
  v: i.literal(1),
  id: i.optional(i.string()),
  // For Firestore ID data
  name: i.string(),
  requests: i.array(
    i.lazy(
      () => i.union([
        $t(Dt),
        $t(Rr)
      ])
    )
  )
}), Ya = mm.extend({
  folders: i.lazy(() => i.array(Ya))
}), Cn = j({
  initial: !0,
  schema: Ya
}), Ja = i.object({
  v: i.literal(2),
  id: i.optional(i.string()),
  // For Firestore ID data
  name: i.string(),
  requests: i.array(
    i.lazy(
      () => i.union([
        $t(Dt),
        $t(Rr)
      ])
    )
  ),
  auth: i.union([Pa, za]),
  headers: i.union([wa, i.array(kr)])
}), Xa = Ja.extend({
  folders: i.lazy(() => i.array(Xa))
}), gm = j({
  initial: !1,
  schema: Xa,
  up(e) {
    const t = {
      ...e,
      v: 2,
      auth: {
        authActive: !0,
        authType: "inherit"
      },
      headers: []
    };
    return e.id && (t.id = e.id), t;
  }
}), _m = Ja.extend({
  v: i.literal(3),
  // AWS Signature Authorization type addition
  auth: i.union([Or, Qa]),
  // `description` field addition under `headers`
  headers: i.union([jr, i.array(Fa)])
}), es = _m.extend({
  folders: i.lazy(() => i.array(es))
}), bm = j({
  initial: !1,
  schema: es,
  up(e) {
    const t = e.headers.map(
      (n) => ({
        ...n,
        description: ""
      })
    );
    return {
      ...e,
      v: 3,
      headers: t
    };
  }
}), Tm = i.object({
  v: i.number()
}), Kg = Mt({
  latestVersion: 3,
  versionMap: {
    1: Cn,
    2: gm,
    3: bm
  },
  getVersion(e) {
    const t = Tm.safeParse(e);
    return t.success ? t.data.v : Cn.schema.safeParse(e).success ? 1 : null;
  }
}), $m = 3;
function ts(e) {
  return {
    v: $m,
    ...e
  };
}
function Am(e) {
  const t = e.name ?? "Untitled", r = (e.folders ?? []).map(Am), n = (e.requests ?? []).map(Xy), a = e.auth ?? { authType: "inherit", authActive: !0 }, s = e.headers ?? [], o = ts({
    name: t,
    folders: r,
    requests: n,
    auth: a,
    headers: s
  });
  return e.id && (o.id = e.id), o;
}
function Sm(e) {
  const t = e.name ?? "Untitled", r = (e.folders ?? []).map(Sm), n = (e.requests ?? []).map(ym), a = e.auth ?? { authType: "inherit", authActive: !0 }, s = e.headers ?? [], o = ts({
    name: t,
    folders: r,
    requests: n,
    auth: a,
    headers: s
  });
  return e.id && (o.id = e.id), o;
}
var te = function(e) {
  return function(t) {
    return !e(t);
  };
};
globalThis && globalThis.__spreadArray;
var Em = zs, wm = Bs;
function rs(e, t) {
  return t === void 0 ? function(r) {
    return rs(e, r);
  } : wm(e, t) ? Zn : Un(t[e]);
}
var ns = function(e) {
  return function(t) {
    return t.map(function(r) {
      return e(r);
    });
  };
}, xm = function(e) {
  return function(t) {
    for (var r = [], n = 0; n < t.length; n++) {
      var a = e(n, t[n]);
      Dn(a) && r.push(a.value);
    }
    return r;
  };
}, jm = function(e) {
  return xm(function(t, r) {
    return e(r);
  });
}, as = function(e) {
  return e.slice();
}, Om = qs, In = globalThis && globalThis.__spreadArray || function(e, t, r) {
  if (r || arguments.length === 2)
    for (var n = 0, a = t.length, s; n < a; n++)
      (s || !(n in t)) && (s || (s = Array.prototype.slice.call(t, 0, n)), s[n] = t[n]);
  return e.concat(s || Array.prototype.slice.call(t));
}, km = function(e) {
  return function(t) {
    return In(In([], t, !0), [e], !1);
  };
}, Rm = km, Cm = function(e) {
  return [e];
}, Im = Rm, Nm = rs, Pm = function(e) {
  return function(t) {
    return t.map(function(r) {
      return e(r);
    });
  };
}, Vm = function() {
  return {
    concat: function(e, t) {
      return e.concat(t);
    }
  };
}, Mm = function() {
  return {
    concat: Vm().concat,
    empty: []
  };
}, Ut = Zn, Ye = Un;
function ss(e) {
  return function(t) {
    return e(t) ? Ye(t) : Ut;
  };
}
var ir = function(e) {
  return function(t) {
    return is(t) ? Ut : Ye(e(t.value));
  };
}, Lm = Dn, is = function(e) {
  return e._tag === "None";
}, Dm = function(e, t) {
  return function(r) {
    return is(r) ? e() : t(r.value);
  };
}, Zm = Dm, Cr = Zm, Um = function(e, t) {
  for (var r = t(e); r._tag === "Left"; )
    r = t(r.left);
  return r.right;
}, re = Cs, B = Is, Hm = /* @__PURE__ */ xs(2, function(e, t) {
  return ye(e) ? e : t(e.right);
}), Je = function(e) {
  return function(t) {
    return ye(t) ? t : B(e(t.right));
  };
}, Ht = function(e) {
  return function(t) {
    return ye(t) ? re(e(t.left)) : t;
  };
}, ye = ks, qm = Rs, Bm = function(e) {
  return function(t) {
    return ye(t) ? e(t.left) : t.right;
  };
}, Ir = Bm, Nn = Hm, St = globalThis && globalThis.__assign || function() {
  return St = Object.assign || function(e) {
    for (var t, r = 1, n = arguments.length; r < n; r++) {
      t = arguments[r];
      for (var a in t)
        Object.prototype.hasOwnProperty.call(t, a) && (e[a] = t[a]);
    }
    return e;
  }, St.apply(this, arguments);
}, Xe = function(e, t, r) {
  return B({
    value: e,
    next: t,
    start: r
  });
}, et = function(e, t, r) {
  return t === void 0 && (t = []), r === void 0 && (r = !1), re({
    input: e,
    expected: t,
    fatal: r
  });
}, Gm = function(e, t) {
  return St(St({}, e), { expected: t });
}, zm = function(e, t) {
  return Km().concat(e, t);
}, Km = function() {
  return {
    concat: function(e, t) {
      return e.input.cursor < t.input.cursor ? Zs().concat(e, t) : e.input.cursor > t.input.cursor ? Gt().concat(e, t) : Ds({
        input: Gt(),
        fatal: Gt(),
        expected: Mm()
      }).concat(e, t);
    }
  };
}, Fm = function(e, t) {
  return t === void 0 && (t = 0), {
    buffer: e,
    cursor: t
  };
}, Qm = function(e) {
  return Nm(e.cursor, e.buffer);
}, Wm = function(e) {
  return e.cursor >= e.buffer.length;
}, Ym = function(e) {
  return m(Qm(e), ir(function(t) {
    return { value: t, next: { buffer: e.buffer, cursor: e.cursor + 1 } };
  }));
}, ee = globalThis && globalThis.__assign || function() {
  return ee = Object.assign || function(e) {
    for (var t, r = 1, n = arguments.length; r < n; r++) {
      t = arguments[r];
      for (var a in t)
        Object.prototype.hasOwnProperty.call(t, a) && (e[a] = t[a]);
    }
    return e;
  }, ee.apply(this, arguments);
}, Nr = function(e) {
  return function(t) {
    return Xe(e, t, t);
  };
}, Jm = function() {
  return function(e) {
    return et(e);
  };
}, Xm = function(e) {
  return function() {
    return et(e);
  };
}, k = function(e) {
  return m(rg(eg()), le(function(t) {
    var r = t[0], n = t[1];
    return e(r) ? me(r) : Xm(n);
  }));
}, O = function(e, t) {
  return function(r) {
    return m(e(r), Ht(function(n) {
      return Gm(n, [t]);
    }));
  };
}, eg = function() {
  return function(e) {
    return m(Ym(e), Cr(function() {
      return et(e);
    }, function(t) {
      var r = t.value, n = t.next;
      return Xe(r, n, e);
    }));
  };
}, tg = function(e, t) {
  return function(r) {
    return m(e(r), Nn(function(n) {
      return m(t(n.value)(n.next), Nn(function(a) {
        return Xe(a.value, a.next, r);
      }));
    }));
  };
}, qt = function(e, t) {
  return function(r) {
    var n = e(r);
    return qm(n) || n.left.fatal ? n : m(t()(r), Ht(function(a) {
      return zm(n.left, a);
    }));
  };
}, rg = function(e) {
  return function(t) {
    return m(e(t), Je(function(r) {
      return ee(ee({}, r), { value: [r.value, t] });
    }));
  };
}, os = function(e) {
  return de(function() {
    return me(e.empty);
  });
}, Pr = function() {
  return O(function(e) {
    return Wm(e) ? Xe(void 0, e, e) : et(e);
  }, "end of file");
}, ng = function(e) {
  return m(Vr(e), de(function() {
    return me([]);
  }));
}, Vr = function(e) {
  return m(e, le(function(t) {
    return Lr(Cm(t), function(r) {
      return m(e, x(function(n) {
        return re(Im(n)(r));
      }), de(function() {
        return me(B(r));
      }));
    });
  }));
}, ag = function(e, t) {
  return function(r) {
    return m(e, le(function() {
      return r;
    }), Dr(function() {
      return t;
    }));
  };
}, cs = function(e) {
  return ag(e, e);
}, sg = function(e) {
  return ng(k(te(e)));
}, Mr = function(e, t) {
  return m(t, x(function() {
    return Om;
  }), de(function() {
    return ig(e, t);
  }));
}, ig = function(e, t) {
  return m(e, le(function(r) {
    return Lr(Ks(r), function(n) {
      return m(t, x(function() {
        return B(n);
      }), de(function() {
        return m(e, x(function(a) {
          return re(Em(a)(n));
        }));
      }));
    });
  }));
}, tt = function(e, t) {
  return function(r) {
    return m(e(r), Je(function(n) {
      return ee(ee({}, n), { value: t(n.value) });
    }));
  };
}, us = function(e, t) {
  return Bt(e, function(r) {
    return tt(t, r);
  });
}, Bt = function(e, t) {
  return tg(e, t);
}, Lr = function(e, t) {
  var r = function(n) {
    return function(a) {
      return ye(a.value) ? re({ value: a.value.left, stream: a.next }) : B(Xe(a.value.right, a.next, n));
    };
  };
  return function(n) {
    return Um({ value: e, stream: n }, function(a) {
      var s = t(a.value)(a.stream);
      return ye(s) ? B(et(a.stream, s.left.expected, s.left.fatal)) : r(n)(s.right);
    });
  };
}, og = function(e, t) {
  return qt(e, t);
}, x = function(e) {
  return function(t) {
    return tt(t, e);
  };
}, me = Nr, le = function(e) {
  return function(t) {
    return Bt(t, e);
  };
}, Dr = function(e) {
  return function(t) {
    return Bt(t, function(r) {
      return tt(e(r), function() {
        return r;
      });
    });
  };
}, de = function(e) {
  return function(t) {
    return og(t, e);
  };
}, cg = "Parser", ug = function(e) {
  return {
    concat: function(t, r) {
      return us(tt(t, function(n) {
        return function(a) {
          return e.concat(n, a);
        };
      }), r);
    }
  };
}, lg = function(e) {
  return ee(ee({}, ug(e)), { empty: Nr(e.empty) });
}, dg = {
  URI: cg,
  map: tt,
  ap: us,
  chain: Bt,
  chainRec: Lr
}, ls = function(e, t, r) {
  var n;
  return Object.assign({}, e, (n = {}, n[t] = r, n));
}, ds = function(e) {
  return function(t) {
    return m(t, x(function(r) {
      return ls({}, e, r);
    }));
  };
}, or = function(e, t) {
  return function(r) {
    return m(r, le(function(n) {
      return m(t(n), x(function(a) {
        return ls(n, e, a);
      }));
    }));
  };
}, fg = function(e) {
  return Us(e)(e.empty);
};
Hs.concat;
var pg = os(lr), ge = function(e) {
  return O(k(function(t) {
    return t === e;
  }), '"'.concat(e, '"'));
}, hg = function(e) {
  return O(k(function(t) {
    return t !== e;
  }), 'anything but "'.concat(e, '"'));
}, Zr = function(e) {
  return pg(rt(e));
}, rt = function(e) {
  return m(Vr(e), x(function(t) {
    return t.join("");
  }));
}, Ur = function(e) {
  return "0123456789".indexOf(e) !== -1;
}, cr = O(k(Ur), "a digit"), vg = /^\s$/, fs = function(e) {
  return vg.test(e);
}, ps = O(k(fs), "a whitespace"), yg = function(e) {
  return e === "_";
}, nt = function(e) {
  return /[a-z]/.test(e.toLowerCase());
}, hs = function(e) {
  return nt(e) || Ur(e) || yg(e);
};
O(k(hs), "a word character");
O(k(nt), "a letter");
var mg = function(e) {
  return e.toLowerCase() !== e.toUpperCase();
};
O(k(mg), "an unicode letter");
var vs = function(e) {
  return nt(e) && e === e.toUpperCase();
};
O(k(vs), "an upper case letter");
var ys = function(e) {
  return nt(e) && e === e.toLowerCase();
};
O(k(ys), "a lower case letter");
O(k(te(Ur)), "a non-digit");
var ms = O(k(te(fs)), "a non-whitespace character");
O(k(te(hs)), "a non-word character");
O(k(te(nt)), "a non-letter character");
O(k(te(vs)), "anything but an upper case letter");
O(k(te(ys)), "anything but a lower case letter");
var gs = function(e) {
  return O(dg.chainRec(e, function(t) {
    return m(bg(0, t), Cr(function() {
      return me(B(e));
    }, function(r) {
      return m(ge(r), le(function() {
        return me(re(t.slice(1)));
      }));
    }));
  }), JSON.stringify(e));
}, ur = fg(lg(lr)), Ze = os(lr), gg = function(e) {
  return Ze(_g(e));
}, _g = function(e) {
  return m(Vr(e), x(function(t) {
    return t.join("");
  }));
}, bg = function(e, t) {
  return e >= 0 && e < t.length ? Ye(t.charAt(e)) : Ut;
}, Tg = Zr(ps);
rt(ps);
Zr(ms);
rt(ms);
var $g = function(e) {
  var t = +e;
  return isNaN(t) || e === "" ? Ut : Ye(t);
};
O(m(ur([Ze(ge("-")), rt(cr)]), x(function(e) {
  return +e;
})), "an integer");
O(m(ur([Ze(ge("-")), Zr(cr), Ze(ur([ge("."), rt(cr)]))]), le(function(e) {
  return m($g(e), Cr(function() {
    return Jm();
  }, Nr));
})), "a float");
var Ag = cs(ge('"'))(gg(qt(gs('\\"'), function() {
  return hg('"');
})));
function _s(e) {
  return function(t) {
    return t(Fm(e.split("")));
  };
}
const Pn = (e, t) => (r) => ({
  ...r,
  [e]: t(r[e])
}), Sg = ["#", ":"], _e = cs(Tg), Hr = (e) => (t) => t.join(e), qr = (e) => m(
  sg((t) => e.includes(t)),
  x(Hr(""))
), Eg = W(
  qr,
  Dr(() => k(() => !0))
), bs = m(
  Ag,
  x((e) => JSON.parse(`"${e}"`))
), wg = m(
  _e(bs),
  de(
    () => m(
      qr([":", `
`]),
      x(Hn)
    )
  )
), xg = m(
  _e(bs),
  de(
    () => m(
      qr([`
`]),
      x(Hn)
    )
  )
), Ts = m(
  Ze(gs("#")),
  x(te(qn))
), $s = m(
  _e(Ts),
  ds("commented"),
  or("key", () => _e(wg)),
  Dr(() => ge(":")),
  or("value", () => xg)
), jg = m(
  _e(Ts),
  ds("commented"),
  or("key", () => qt(
    Eg([`
`]),
    () => m(
      Mr(k((e) => !0), Pr()),
      x(W(
        as,
        Hr("")
      ))
    )
  )),
  x(W(
    ss(({ key: e }) => !qn(e))
  ))
), Og = m(
  Mr(_e($s), Pr())
), kg = m(
  Mr(
    qt(
      m($s, x(Ye)),
      () => m(
        jg,
        x(W(
          ir((e) => ({ ...e, value: "" }))
        ))
      )
    ),
    Pr()
  ),
  x(W(
    jm(W(
      ss(Lm),
      ir((e) => e.value)
    ))
  ))
), Rg = (e) => {
  if (Sg.some((n) => e.includes(n)))
    return !0;
  const t = JSON.stringify(e);
  return t.substring(1, t.length - 1).trim() !== e;
}, Vn = (e) => Rg(e) ? JSON.stringify(e) : e, Fg = (e) => m(
  e,
  Pm(
    W(
      Pn("key", Vn),
      Pn("value", Vn),
      ({ key: t, value: r, active: n }) => n ? `${t}: ${r}` : `# ${t}: ${r}`
    )
  ),
  Hr(`
`)
), Cg = (e) => m(
  kg,
  _s(e),
  Ht((t) => ({
    message: `Expected ${t.expected.map((r) => `'${r}'`).join(", ")}`,
    expected: t.expected,
    pos: t.input.cursor
  })),
  Je(
    ({ value: t }) => m(
      t,
      ns(
        ({ key: r, value: n, commented: a }) => ({
          active: !a,
          key: r,
          value: n
        })
      )
    )
  )
), Qg = (e) => m(
  Og,
  _s(e),
  Ht((t) => ({
    message: `Expected ${t.expected.map((r) => `'${r}'`).join(", ")}`,
    expected: t.expected,
    pos: t.input.cursor
  })),
  Je(
    ({ value: t }) => m(
      t,
      ns(
        ({ key: r, value: n, commented: a }) => ({
          active: !a,
          key: r,
          value: n
        })
      )
    )
  )
), Wg = W(
  Cg,
  Je(as),
  Ir(() => [])
), Ig = i.object({
  id: i.optional(i.string()),
  name: i.string(),
  variables: i.array(
    i.object({
      key: i.string(),
      value: i.string()
    })
  )
}), Mn = j({
  initial: !0,
  schema: Ig
}), As = () => Math.random().toString(36).substring(2, 16), Ng = i.object({
  v: i.literal(1),
  id: i.string(),
  name: i.string(),
  variables: i.array(
    i.union([
      i.object({
        key: i.string(),
        secret: i.literal(!0)
      }),
      i.object({
        key: i.string(),
        value: i.string(),
        secret: i.literal(!1).catch(!1)
      })
    ])
  )
}), Pg = j({
  initial: !1,
  schema: Ng,
  up(e) {
    return {
      ...e,
      v: 1,
      id: e.id || As(),
      variables: e.variables.map((r) => ({
        ...r,
        secret: !1
      }))
    };
  }
}), Vg = i.object({
  v: i.number()
}), Yg = Mt({
  latestVersion: 1,
  versionMap: {
    0: Mn,
    1: Pg
  },
  getVersion(e) {
    const t = Vg.safeParse(e);
    return t.success ? t.data.v : Mn.schema.safeParse(e).success ? 0 : null;
  }
}), Et = /<<([^>]*)>>/g, wt = 10, Ss = "ENV_EXPAND_LOOP", Ln = 1;
function Mg(e, t) {
  let r = e, n = 0;
  for (; r.match(Et) != null && n <= wt; )
    r = r.replace(Et, (a) => {
      const s = t.find(
        (o) => o.key === a.replace(/[<>]/g, "")
      );
      return s && "value" in s ? s.value : a;
    }), n++;
  return n > wt ? re(Ss) : B(r);
}
const Jg = (e, t) => m(
  Mg(e, t),
  Ir(() => e)
);
function Lg(e, t, r = !1, n = !1) {
  if (!t || !e)
    return B(e);
  let a = e, s = 0, o = !1;
  for (; a.match(Et) != null && s <= wt && !o; )
    a = decodeURI(encodeURI(a)).replace(Et, (c, l) => {
      const u = t.find((h) => h && h.key === l);
      return u && "value" in u ? u.secret && n ? (o = !0, `<<${l}>>`) : u.secret && r ? "*".repeat(
        u.value.length
      ) : u.value : "";
    }), s++;
  return s > wt ? re(Ss) : B(a);
}
const Xg = (e, t, r = !1, n = !1) => m(
  Lg(e, t, r, n),
  Ir(() => e)
), Dg = (e) => ({
  key: e.key,
  value: e.value,
  secret: !1
}), e_ = (e) => {
  if (e.v && e.v === Ln)
    return e;
  const t = e.id || As(), r = e.name ?? "Untitled", n = (e.variables ?? []).map(Dg);
  return {
    v: Ln,
    id: t,
    name: r,
    variables: n
  };
};
export {
  Na as AuthCodeGrantTypeParams,
  $r as ClientCredentialsGrantTypeParams,
  $m as CollectionSchemaVersion,
  Yg as Environment,
  Ln as EnvironmentSchemaVersion,
  Tr as FormDataKeyValue,
  Fa as GQLHeader,
  Wa as GQL_REQ_SCHEMA_VERSION,
  Kg as HoppCollection,
  Qa as HoppGQLAuth,
  Ba as HoppGQLAuthAPIKey,
  Fe as HoppGQLAuthBasic,
  Qe as HoppGQLAuthBearer,
  We as HoppGQLAuthInherit,
  Ke as HoppGQLAuthNone,
  xr as HoppGQLAuthOAuth2,
  Rr as HoppGQLRequest,
  Or as HoppRESTAuth,
  Ca as HoppRESTAuthAPIKey,
  Za as HoppRESTAuthAWSSignature,
  Be as HoppRESTAuthBasic,
  Ge as HoppRESTAuthBearer,
  ze as HoppRESTAuthInherit,
  qe as HoppRESTAuthNone,
  xr as HoppRESTAuthOAuth2,
  jr as HoppRESTHeaders,
  Da as HoppRESTParams,
  Ma as HoppRESTReqBody,
  Zg as HoppRESTReqBodyFormData,
  Dt as HoppRESTRequest,
  ja as HoppRESTRequestVariables,
  Sr as ImplicitOauthFlowParams,
  Ar as PasswordGrantTypeParams,
  Ua as RESTReqSchemaVersion,
  Ug as ValidContentTypesList,
  vm as getDefaultGQLRequest,
  Jy as getDefaultRESTRequest,
  Hg as isEqualHoppRESTRequest,
  Gg as isHoppRESTRequest,
  Qy as knownContentTypes,
  ts as makeCollection,
  zg as makeGQLRequest,
  Bg as makeRESTRequest,
  Jg as parseBodyEnvVariables,
  Mg as parseBodyEnvVariablesE,
  Wg as parseRawKeyValueEntries,
  Cg as parseRawKeyValueEntriesE,
  Xg as parseTemplateString,
  Lg as parseTemplateStringE,
  Fg as rawKeyValueEntriesToString,
  qg as safelyExtractRESTRequest,
  Qg as strictParseRawKeyValueEntriesE,
  ym as translateToGQLRequest,
  e_ as translateToNewEnvironment,
  Dg as translateToNewEnvironmentVariables,
  Sm as translateToNewGQLCollection,
  Am as translateToNewRESTCollection,
  Xy as translateToNewRequest
};
